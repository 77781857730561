import {FormControl, FormHelperText, MenuItem, Select, InputLabel, Button} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {isFunction} from "../utils";
import InfoDialog from "./InfoDialog";
import {CompTooltipIcon} from "./TooltipIcon";

const CompDropdown = (props) => {
    const {t} = useTranslation('translation');
    let {error} = props
    const {
        rows, label, children, onChange, labelColumn = 'label',
        valueColumn = 'value',
        hasEmptyOption = false,
        selectedValue = "",
        userInfo = false,
        ...componentProps
    } = props;

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    if(error !== undefined && error !== null) {
        error = t("errors."+error);
    } else {
        error=null;
    }

    let emptyOption;

    if (hasEmptyOption) {
        emptyOption = (
            <MenuItem key={`option-empty`} className={`option option-empty`}
                    value={""}>{t('components.fill_out')}</MenuItem>
        )
    } else {
        emptyOption = (
            <MenuItem disabled key={`option-empty`} value={""}> -- {t('components.select')} -- </MenuItem>
        );
    }

    const customRenderValue = (selected) => {

        if (selected === "") {
            emptyOption = ""

            if(hasEmptyOption) {
                return t('components.fill_out')
            } else {
                return "-- " + t('components.select') + " --"
            }
        }
        const selectedOption = rows.find((row) => row[valueColumn] === selected);
        const label = selectedOption ? selectedOption[labelColumn] : '';

        if(label === null) {
            return label
        } else {
            return label.trim()
        }
      };

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };

    return (
        <div>
            <div style={{display: 'flex'}}>
                <InputLabel className={'label'}>{label}</InputLabel>
                    {userInfo && (
                        <div className="tooltip-container">
                            <Button onClick={handleOpenDialog}>
                                <img className={`tooltip-icon-image ${open ? 'hidden' : ''}`}
                                    src={process.env.PUBLIC_URL + '/media/images/icons/IconTooltipInfo.svg'}
                                    alt={t('common.info')}/>
                                <img className={`tooltip-icon-image ${open ? '' : 'hidden'}`}
                                    src={process.env.PUBLIC_URL + '/media/images/icons/IconTooltipInfoFocus.svg'}
                                    alt={t('common.info')}/>
                                </Button>
                                <InfoDialog
                                    open={isDialogOpen}
                                    handleClose={handleCloseDialog}
                                    title={t('user.userRoleTitle')}
                                    message={t('user.userRoleInfo')}
                                ></InfoDialog>
                            </div>
                        )}
            </div>
                <FormControl className={`comp-dropdown-container ${props.className ? 'container-' + props.className : ''} ${error ? 'error' : ''}`}>
                    <Select
                        className={'dropdown-select dropdown_custom'}
                        value={null == selectedValue ? null : selectedValue}
                        {...componentProps}
                        label={label}
                        onChange={handleChange}
                        renderValue={customRenderValue}
                        disableUnderline={true}
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    > 
                    {emptyOption}
                        {rows.map((row, index) => (
                             <MenuItem
                                disabled={row.disabled === true}
                                 style={props.style}
                                key={`option-${index}`}
                                 className={`option option-${index}`}
                                 value={row[valueColumn]}
                             >
                                 {row[labelColumn]}
                            {row.descriptionFi && t('report.current_language') === "FINNISH" && <CompTooltipIcon ignore={true}>{row.descriptionFi}</CompTooltipIcon>}
                            {row.descriptionSv && t('report.current_language') !== "FINNISH" && <CompTooltipIcon ignore={true}>{row.descriptionSv}</CompTooltipIcon>}
                             </MenuItem>
                         ))}
                     </Select>
                 <FormHelperText className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{error}</FormHelperText>
             </FormControl>
         </div>
    );
}

CompDropdown.propTypes = {
    labelColumn: PropTypes.string,
    valueColumn: PropTypes.string,
    label: PropTypes.string,
    rows: PropTypes.array,
    error: PropTypes.string,
    hasEmptyOption: PropTypes.bool,
    onChange: PropTypes.func,
    selectedValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

export {CompDropdown};