import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {
    archiveOrganization,
    createOrganization,
    getMunicipality,
    getOrganization,
    getOrganizationTree,
    listOrganizations,
    saveOrganization,
    setOrganization,
    unsetOrganization
} from "../municipality.actions";
import {ListMunicipalities} from "./listMunicipalitiesComponent";
import {OrganizationFormComponent} from "./organizationFormComponent";
import {ButtonMain} from "../../../components/ButtonMain";
import {CompListTree} from "../../../components/ListTree";
import {ButtonSecondary} from "../../../components/ButtonSecondary";
import {ApiKeyComponent} from "./apiKeyComponent";
import { CompPartitionHeader } from '../../../components/PartitionHeader';
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {Trans, withTranslation} from "react-i18next";
import {
    ROLE_ADMIN_SYSTEM,
    hasPermission,
    hasRole,
    PERMISSION_CREATE_ORGANIZATION,
    PERMISSION_EDIT_ORGANIZATION,
} from "../../../permissions";

const mapStateToProps = (state) => {
    return {
        selectedMunicipality: state.municipality.selectedMunicipality,
        selectedOrganization: state.municipality.selectedOrganization,
        organizations: state.municipality.organizations,
        organizationFieldErrors: state.municipality.organizationFieldErrors,
        organizationTree: state.municipality.organizationTree,
        authenticatedUser: state.auth.authenticatedUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMunicipality: (id) => dispatch(getMunicipality(id)),
        listOrganizations: (municipalityId) => dispatch(listOrganizations(municipalityId)),
        getOrganization: (municipalityId, id) => dispatch(getOrganization(municipalityId, id)),
        unsetOrganization: () => dispatch(unsetOrganization()),
        setOrganization: selectedOrganization => dispatch(setOrganization(selectedOrganization)),
        saveOrganization: (municipalityId, id, data, onSuccess) => dispatch(saveOrganization(municipalityId, id, data, onSuccess)),
        createOrganization: (municipalityId, organization, onSuccess) => dispatch(createOrganization(municipalityId, organization, onSuccess)),
        getOrganizationTree: (municipalityId, level) => dispatch(getOrganizationTree(municipalityId, level)),
        archiveOrganization: (municipalityId, id, onSuccess) => dispatch(archiveOrganization(municipalityId, id, onSuccess))
    };
}

class OrganizationPage extends React.Component {
    constructor(props) {
        super(props);
        this.organizationTreeLevel = 10;
        this.organizationTreeEditLevel = 9;
        this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeLevel);
        this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeEditLevel);
        this.state = {
            editorActivated: false,
            createNewOrganizationModalOpen: false,
            archiveModalOpen: false,
            keyModalOpen: false
        }
        this.closeApiKeyModal = this.closeApiKeyModal.bind(this);
        this.openApiKeyModal = this.openApiKeyModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.closeCreateNewModal = this.closeCreateNewModal.bind(this);
        this.handleActivateEditor = this.handleActivateEditor.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetOrganization = this.resetOrganization.bind(this);
        this.closeArchiveModal = this.closeArchiveModal.bind(this);
        this.archive = this.archive.bind(this);
        this.openArchiveModal = this.openArchiveModal.bind(this);
    }

    handleChange(e) {
        const data = {
            ...this.props.selectedOrganization,
            [e.target.name]: e.target.value
        }
        this.props.setOrganization(data);
    }

    handleCreateNew(event) {
        event.preventDefault()
        this.props.unsetOrganization()
        this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeEditLevel);
        this.setState({...this.state, createNewOrganizationModalOpen: true})
    }

    closeCreateNewModal(event) {
        event.preventDefault()
        this.setState({...this.state, createNewOrganizationModalOpen: false})
    }

    archive(event) {
        event.preventDefault()
        this.props.archiveOrganization(this.props.selectedMunicipality.id, this.props.selectedOrganization.id, () => {
            this.setState({
                ...this.state,
                archiveModalOpen: false,
                createNewOrganizationModalOpen: false,
                editorActivated: false
            });
            this.props.listOrganizations(this.props.selectedMunicipality.id);
            return unsetOrganization();
        })
    }

    openArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: true})
    }

    closeArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: false})
    }

    closeApiKeyModal() {
        this.setState({...this.state, keyModalOpen: false})
    }
    openApiKeyModal() {
        this.setState({...this.state, keyModalOpen: true})
    }


    handleActivateEditor() {
        this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeEditLevel);
        this.setState({...this.state, editorActivated: true})
    }

    handleSubmit(event, props) {
        const data = {...props};
        const {id} = props;
        if (id) {
            this.props.saveOrganization(this.props.selectedMunicipality.id, id, data, data => {
                this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeLevel);
                //this.props.listOrganizations(this.props.selectedMunicipality.id);
                this.setState({editorActivated: false})
                return setOrganization(data);
            })
        } else {
            data['municipalityId'] = this.props.selectedMunicipality.id;
            this.props.createOrganization(this.props.selectedMunicipality.id, data, data => {
                this.setState({
                    ...this.state, createNewOrganizationModalOpen: false,
                    editorActivated: false
                })
                this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeLevel);
                //this.props.listOrganizations(this.props.selectedMunicipality.id);
                return setOrganization(data);
            })
        }
    }

    resetOrganization = () => {
        if (Object.keys(this.props.selectedOrganization).length === 0) {
            this.selectOrganization({id: this.props.selectedOrganization.id}, false)
        } else {
            this.selectOrganization({id: this.props.selectedOrganization.id}, true)
        }
    }

    selectMunicipality = (row ) => {
        this.setState({editorActivated: false})
        this.props.getMunicipality(row.target.value);
        this.props.getOrganizationTree(row.target.value, this.organizationTreeLevel);
        this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeEditLevel);
        this.props.unsetOrganization();
    }

    // selectMunicipality = (row, isAddAction/*, index */) => {
    //     this.setState({editorActivated: false})
    //     if (isAddAction) {
    //         this.props.getMunicipality(row.id);
    //         this.props.getOrganizationTree(row.id, this.organizationTreeLevel);
    //         this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeEditLevel);
    //     } else {
    //         this.props.getMunicipality(this.props.authenticatedUser.mainMunicipalityId);
    //     }
    //     this.props.unsetOrganization();
    // }

    selectOrganization = (row, isAddAction/*, index */) => {
        this.setState({editorActivated: false})
        if (isAddAction) {
            this.props.getOrganization(this.props.selectedMunicipality.id, row.id);
            this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeLevel);
            this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeEditLevel);
        } else {
            this.props.unsetOrganization();
        }
    }

    render() {
        const {t} = this.props;
        const selectedOrganization = [];
        let selectedOrganizationName = "";
        if (this.props.selectedOrganization && this.props.selectedOrganization.id) {
            selectedOrganization.push(this.props.selectedOrganization.id);
            selectedOrganizationName = this.props.selectedOrganization.name;
        }


        let actionButtons = [];
        if (this.state.editorActivated) {
            actionButtons.push({
                type: "attention",
                label: t('common.archive'),
                className: "archive-button",
                onClick: this.openArchiveModal
            });
            actionButtons.push({
                type: "secondary",
                label: t('common.cancel_editing'),
                className: "",
                onClick: this.resetOrganization
            });
            actionButtons.push({
                type: "main",
                label: t('common.save_changes'),
                className: "",
                onClick: this.handleSubmit
            });
        }

        let addEditButtons = [];

        if (hasRole(ROLE_ADMIN_SYSTEM, this.props.authenticatedUser) || (hasPermission(PERMISSION_CREATE_ORGANIZATION, this.props.authenticatedUser)
            && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id)
        ) {
            addEditButtons.push(
                <ButtonMain disabled={this.state.editorActivated}
                            key={'add_new_organization'}
                            onClick={this.handleCreateNew}>
                    {t("municipality.add_new_organization")}
                </ButtonMain>
            );
        }
        if (hasRole(ROLE_ADMIN_SYSTEM, this.props.authenticatedUser) || (hasPermission(PERMISSION_EDIT_ORGANIZATION, this.props.authenticatedUser)
            && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedOrganization.municipalityId)
        ) {
            addEditButtons.push(
                <ButtonMain
                    disabled={this.state.editorActivated || Object.keys(this.props.selectedOrganization).length === 0}
                    key={'edit_organization'}
                    onClick={this.handleActivateEditor}>
                    {t("municipality.edit_organization")}
                </ButtonMain>
            );
        }

        return (
            <div>
                <Grid container direction='row'>
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={t('municipality.municipality_organizations')}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid item xs={12}>
                                <ListMunicipalities onSelect={this.selectMunicipality}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CompListTree
                                className={`listOrganizationsComponent`}
                                onSelect={this.selectOrganization}
                                rows={this.props.organizationTree[this.organizationTreeLevel]}
                                selectedRows={selectedOrganization}
                                keyColumn={'id'}
                                valueColumn={'name'}>
                                {t("municipality.select_organization")}
                            </CompListTree>
                        </Grid>
                            <Grid item xs={12} className={'align-right'}>
                                <ButtonSpacer>
                                    {addEditButtons}
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <OrganizationFormComponent
                            openApiKeyModal={this.openApiKeyModal}
                            showApiKeyButton={!this.state.editorActivated && this.props.selectedOrganization.id &&
                                ((hasRole(ROLE_ADMIN_SYSTEM, this.props.authenticatedUser)) || 
                                (hasPermission(PERMISSION_EDIT_ORGANIZATION, this.props.authenticatedUser) && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id) ||
                                (this.props.authenticatedUser.organizations.some(i => i.id === this.props.selectedOrganization.id) && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id))
                            }
                            label={t("municipality.selected_organization_info")}
                            editorActivated={this.state.editorActivated}
                            onChange={this.handleChange}
                            selectedOrganization={this.props.selectedOrganization}
                            selectedMunicipality={this.props.selectedMunicipality}
                            fieldErrors={this.props.organizationFieldErrors}
                            organizationTree={this.props.organizationTree[this.organizationTreeEditLevel]}
                            actionButtons={actionButtons}
                        />
                    </Grid>
                </Grid>
                <Modal
                  open={this.state.keyModalOpen}
                  onClose={this.closeApiKeyModal}
                >
                    <div className={'modal'}>
                        <ApiKeyComponent
                            municipalityId={null}
                            organizationId={this.props.selectedOrganization ? this.props.selectedOrganization.id : null}
                            createApiKey={(hasRole(ROLE_ADMIN_SYSTEM, this.props.authenticatedUser)) || (hasPermission(PERMISSION_EDIT_ORGANIZATION, this.props.authenticatedUser) && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id)}
                            labelText={t("municipality.organization_key")+" - "+this.props.selectedOrganization.name}
                            onClose={this.closeApiKeyModal}
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.state.createNewOrganizationModalOpen}
                    onClose={this.closeCreateNewModal}
                >
                    <div className={'modal'}>

                        <OrganizationFormComponent
                            label={t("municipality.create_new_organization")}
                            editorActivated={this.state.createNewOrganizationModalOpen}
                            onChange={this.handleChange}
                            selectedOrganization={this.props.selectedOrganization}
                            selectedMunicipality={this.props.selectedMunicipality}
                            fieldErrors={this.props.organizationFieldErrors}
                            organizationTree={this.props.organizationTree[this.organizationTreeEditLevel]}
                            actionButtons={[
                                {
                                    type: "secondary",
                                    label: t("common.leave_without_saving"),
                                    className: "",
                                    onClick: this.closeCreateNewModal
                                },
                                {
                                    type: "main",
                                    label: t("municipality.save_organization"),
                                    className: "",
                                    onClick: this.handleSubmit
                                },
                            ]}
                        />

                    </div>
                </Modal>
                <Modal
                    open={this.state.archiveModalOpen}
                    onClose={this.closeCreateNewModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalDescription'}>
                            <Trans i18nKey="municipality.org_archive_desc"
                                   selectedOrganizationName={selectedOrganizationName}/>
                        </div>
                        <div className={'modalQuestion'}>
                            {t('municipality.org_archive_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSpacer>
                                <ButtonSecondary onClick={this.closeArchiveModal}>
                                    {t('common.cancel')}
                                </ButtonSecondary>
                                <ButtonMain onClick={this.archive}>
                                    {t('common.archive')}
                                </ButtonMain>
                            </ButtonSpacer>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

const OrganizationPageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(OrganizationPage));

export {OrganizationPageForm};