import types from "./municipality.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";

const setMunicipalities = data => {
    return {
        type: types.SET_MUNICIPALITIES_LIST,
        payload: data
    };
}

const setMunicipality = (data, municipalityData) => {
    const payload = {
        municipalityUserData: data,
        municipalityData: municipalityData
    }
    return {
        type: types.SET_MUNICIPALITY,
        payload: payload
    };
}

const setMunicipalitySilent = (data, municipalityData) => {
    const payload = {
        municipalityUserData: data,
        municipalityData: municipalityData
    }
    return {
        type: types.SET_MUNICIPALITY_SILENT,
        payload: payload
    };
}

const municipalityAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.MUNICIPALITY_API_ERROR,
        payload: data
    }
}

const organizationAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.ORGANIZATION_API_ERROR,
        payload: data
    }
}

export const createMunicipality = (data, onSuccess) => {
    return apiAction({
        url: settings.baseURL + "/municipalities/",
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: municipalityAPICallError,
        label: types.CREATE_MUNICIPALITY
    });
};

export const listMunicipalityUsers = (municipalityId, municipalityData) => {
    return apiAction({
        url: `${settings.baseURL}/users/?municipalityId=${municipalityId}`,
        onSuccess: (data) => setMunicipality(data, municipalityData),
        onFailure: municipalityAPICallError,
        label: types.FETCH_MUNICIPALITIES_LIST
    });
}

export const listMunicipalityUsersSilent = (municipalityId, municipalityData) => {
    return apiAction({
        url: `${settings.baseURL}/users/?municipalityId=${municipalityId}`,
        onSuccess: (data) => setMunicipalitySilent(data, municipalityData),
        onFailure: municipalityAPICallError,
        label: types.FETCH_MUNICIPALITIES_LIST
    });
}

export const listMunicipalities = () => {
    return apiAction({
        url: settings.baseURL + "/municipalities/",
        onSuccess: setMunicipalities,
        onFailure: municipalityAPICallError,
        label: types.FETCH_MUNICIPALITIES_LIST
    });
}

export const getMunicipality = id => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${id}/`,
        onSuccess: (data) => listMunicipalityUsers(id, data),
        onFailure: municipalityAPICallError,
        label: types.FETCH_MUNICIPALITY
    });
}

export const setMunicipalityData = data => {
    return {
        type: types.SET_MUNICIPALITY_DATA,
        payload: data
    };
}

export const unsetMunicipality = () => {
    return {
        type: types.UNSET_MUNICIPALITY,
        payload: null
    };
}

export const saveMunicipality = (id, data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${id}/`,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: municipalityAPICallError,
        label: types.UPDATE_MUNICIPALITY
    });
}

/** KEYS */
export const getKey = (municipalityId, organizationId, onSuccess) => {
    return apiAction({
        url: municipalityId ? `${settings.baseURL}/api-key/get-municipality-key/${municipalityId}` : `${settings.baseURL}/api-key/get-organization-key/${organizationId}`,
        method: 'GET',
        onSuccess: onSuccess,
        onFailure: (data) => {
            return {
                type: types.IGNORE,
                payload: data
            }
        },
        label: municipalityId ? types.GET_KEY_MUNICIPALITY : types.GET_KEY_ORGANIZATION
    });
}

export const createKey = (data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/api-key/create`,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: (data) => {
            return {
                type: types.IGNORE,
                payload: data
            }
        },
        label: types.CREATE_KEY
    });
}

export const deleteKey = (data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/api-key/delete`,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: (data) => {
            return {
                type: types.IGNORE,
                payload: data
            }
        },
        label: types.CREATE_KEY
    });
}

/** ORGANIZATION ***/

export const createOrganization = (municipalityId, data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${municipalityId}/organizations/`,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: organizationAPICallError,
        label: types.CREATE_ORGANIZATION
    });
};

export const listOrganizations = (municipalityId) => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${municipalityId}/organizations/`,
        onSuccess: setOrganizations,
        onFailure: organizationAPICallError,
        label: types.FETCH_ORGANIZATIONS_LIST
    });
}

const setOrganizations = data => {
    return {
        type: types.SET_ORGANIZATIONS_LIST,
        payload: data
    };
}

export const getOrganization = (municipalityId,id) => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${municipalityId}/organizations/${id}/`,
        onSuccess: setOrganization,
        onFailure: organizationAPICallError,
        label: types.FETCH_ORGANIZATION
    });
}
export const getOrganizationTree = (municipalityId,level=3) => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${municipalityId}/organizationtree/${level}`,
        onSuccess: (data) => setOrganizationTree(level, data),
        onFailure: organizationAPICallError,
        label: types.FETCH_ORGANIZATION_TREE
    });
}

export const setOrganization = data => {
    return {
        type: types.SET_ORGANIZATION,
        payload: data
    };
}
export const setOrganizationTree = (level,data) => {
    return {
        type: types.SET_ORGANIZATION_TREE,
        payload: {
            level:level,
            data:data
        }
    };
}

export const unsetOrganization = () => {
    return {
        type: types.UNSET_ORGANIZATION,
        payload: null
    };
}

export const saveOrganization = (municipalityId, id, data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${municipalityId}/organizations/${id}/`,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: organizationAPICallError,
        label: types.UPDATE_ORGANIZATION
    });
}

export const archiveOrganization = (municipalityId, id,onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/municipalities/${municipalityId}/organizations/${id}/archive`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: organizationAPICallError,
        label: types.ARCHIVE_ORGANIZATION
    });
};


