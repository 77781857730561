import React from "react";

import {Grid} from "@material-ui/core";

import * as PropTypes from "prop-types";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {CompTextField} from "../../../components/TextField";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {CompListTree} from "../../../components/ListTree";
import {generateKey, isFunction} from "../../../utils";
import {useTranslation} from "react-i18next";
import {selectButtonType} from "../../../components/utils";
import {ButtonMain} from "../../../components/ButtonMain";

const OrganizationFormComponent = (props) => {
    const {
        openApiKeyModal,
        showApiKeyButton = false,
        label,
        onChange,
        editorActivated,
        actionButtons,
        selectedOrganization = {},
        selectedMunicipality = {},
        fieldErrors = {},
        organizationTree = [],
        ...componentProps
    } = props;

    const {t} = useTranslation('translation');

    const selectOrganization = (row, isAddAction/*, index */) => {
        let value = null;
        if (isAddAction) {
            value = row.id;
        }
        const data = {
            target: {
                ...componentProps,
                value: value,
                name: 'parentId',
            },
        };
        if (isFunction(onChange)) {
            onChange(data);
        }
    }

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const handleClick = (event, callback) => {
        event.preventDefault()
        const data = {...selectedOrganization}
        callback(event, data);
    };

    const showApiKeyModal = (event) => {
        event.preventDefault()
        openApiKeyModal()
    };

    let componentActionButtons = [];
    if (actionButtons) {
        actionButtons.forEach(function (button) {
            const key = generateKey('orgEditButton');
            selectButtonType(button, componentActionButtons, key, handleClick);
        });
    }

    return (
        <form noValidate autoComplete="off" className={"municipality-container"}>  
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={label}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12}>
                            <CompTextField
                                label={t('common.municipality')}
                                value={selectedMunicipality.name}
                                disabled={true}
                                name="municipality"
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CompTextField
                                label={`${t('municipality.organization_name')}*`}
                                value={selectedOrganization.name}
                                disabled={!editorActivated}
                                error={fieldErrors.name}
                                name="name"
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CompListTree
                                onSelect={selectOrganization}
                                rows={organizationTree}
                                selectedRows={[selectedOrganization.parentId]}
                                disabled={!editorActivated}
                                error={fieldErrors.parentId}
                                name="parentId"
                                wholeTreeOpen={true}
                                keyColumn={'id'}
                                valueColumn={'name'}>
                                {t('municipality.main_organization')}
                            </CompListTree>
                        </Grid>
                        <Grid item xs={12} className={'align-right'}>
                            <Grid
                                container spacing={1}
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={'bottom-button-container'}>
                                <Grid item xs={12} className={'align-right'}>
                                    <ButtonSpacer>
                                        {componentActionButtons}
                                    </ButtonSpacer>
                                </Grid>
                            </Grid>
                        </Grid>             
                        { showApiKeyButton && true &&
                        <Grid item xs={12}>
                            <ButtonMain
                                key={'show_api_key'}
                                onClick={showApiKeyModal}>
                                {t("municipality.show_api_key")}
                            </ButtonMain>
                        </Grid> 
                        }
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

OrganizationFormComponent.propTypes =
    {
        editorActivated: PropTypes.bool,
        onChange: PropTypes.func,
        selectedOrganization: PropTypes.object,
        selectedMunicipality: PropTypes.object,
        actionButtons: PropTypes.array,
        organizationTree: PropTypes.array
    }
;

export {OrganizationFormComponent};