import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {ButtonMain} from "../../../components/ButtonMain";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {CompFunctionCards} from "./FunctionCards";
import {FunctionForm} from "./functionFormComponent";
import {
    archiveFunction,
    createFunction,
    listFunctions, listFunctionTemplates, listOwnFunctions, paginatedListFunctions,
    saveFunction,
    setFunction, setFunctions, setEmptyAction,
    unsetFunction,
    updateFunctionTemplate, deleteFunctionTemplate, getFunction, removemeFunctionTemplate, getYouthCardInfo
} from "../function.actions";
import {listLocations, setLocation, unsetLocation} from "../../location/location.actions";
import {Trans, withTranslation} from "react-i18next";
import {CompSearchInput} from "../../../components/SearchInput";
import {
    getMunicipality,
    listMunicipalities,
    listMunicipalityUsers,
    listOrganizations,
    getOrganizationTree
} from "../../municipality/municipality.actions";
import {CompDatePicker} from "../../../components/DatePicker";
import {ButtonSecondary} from "../../../components/ButtonSecondary";
import {
    hasPermission, hasPermissionToHandleMunicipality, PERMISSION_ARCHIVE_ANY_FUNCTION, PERMISSION_ARCHIVE_OWN_FUNCTION,
    PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER,
    PERMISSION_CREATE_FUNCTION_TEMPLATE,
    PERMISSION_CREATE_OWN_FUNCTION, PERMISSION_EDIT_ANY_FUNCTIONS, PERMISSION_EDIT_OWN_FUNCTIONS
} from "../../../permissions";
import {getReminders} from "../../user/user.actions";
import {CompDropdown} from "../../../components/Dropdown";


const mapStateToProps = (state) => {
    return {
        municipalities: state.municipality.municipalities,
        selectedFunction: state.func.selectedFunction,
        selectedMunicipality: state.municipality.selectedMunicipality,
        selectedOrganization: state.municipality.selectedOrganization,
        selectedLocation: state.location.selectedLocation,
        functionFieldErrors: state.func.functionFieldErrors,
        authenticatedUser: state.auth.authenticatedUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listMunicipalities: () => dispatch(listMunicipalities()),
        setFunction: (data) => dispatch(setFunction(data)),
        setLocation: (data) => dispatch(setLocation(data)),
        unsetLocation: () => dispatch(unsetLocation()),
        unsetFunction: () => dispatch(unsetFunction()),
        getFunction: (id) => dispatch(getFunction(id)),
        getMunicipality: (id) => dispatch(getMunicipality(id)),
        listFunctions: (filters, onSuccess) => dispatch(listFunctions(filters, onSuccess)),
        listOwnFunctions: (userId, onSuccess) => dispatch(listOwnFunctions(userId, onSuccess)),
        paginatedListFunctions: (municipalityId, functionType, startDateEnd, organizerId, referenceId, limit, direction, onSuccess) => dispatch(paginatedListFunctions(municipalityId, functionType, startDateEnd, organizerId, referenceId, limit, direction, onSuccess)),
        listFunctionTemplates: (municipalityId) => dispatch(listFunctionTemplates(municipalityId)),
        listLocations: (municipalityId) => dispatch(listLocations(municipalityId)),
        listMunicipalityUsers: (municipalityId) => dispatch(listMunicipalityUsers(municipalityId)),
        listOrganizations: (municipalityId) => dispatch(listOrganizations(municipalityId)),
        getOrganizationTree: (municipalityId, level) => dispatch(getOrganizationTree(municipalityId, level)),
        createFunction: (data, createTemplate, onSuccess, onlyTemplate) => dispatch(createFunction(data, createTemplate, onSuccess, onlyTemplate)),
        saveFunction: (id, data, onSuccess) => dispatch(saveFunction(id, data, onSuccess)),
        archiveFunction: (id, onSuccess) => dispatch(archiveFunction(id, onSuccess)),
        getReminders: () => dispatch(getReminders()),
        //updateContentIdentifierChoices: (municipalityId) => dispatch(updateContentIdentifierChoices(municipalityId)),
        updateFunctionTemplate: (templateId, data, onSuccess) => dispatch(updateFunctionTemplate(templateId, data, onSuccess)),
        deleteFunctionTemplate: (templateId, onSuccess) => dispatch(deleteFunctionTemplate(templateId, onSuccess)),
        removemeFunctionTemplate: (templateId, onSuccess) => dispatch(removemeFunctionTemplate(templateId, onSuccess)),
        getYouthCardInfo: (eventId, onSuccess) => dispatch(getYouthCardInfo(eventId, onSuccess))
    };
}


class FunctionPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.listMunicipalities();
        this.props.listFunctionTemplates(this.props.selectedMunicipality.id);
        this.props.listLocations(this.props.selectedMunicipality.id);
        this.props.listOrganizations(this.props.selectedMunicipality.id);
        this.props.getOrganizationTree(this.props.selectedMunicipality.id, 10);

        const action = this.props.action;
        const editid = this.props.editid;

        let functionModalOpen = false;

        if (action === 'create' || action === 'edit' || editid) {
            this.props.unsetFunction();
            functionModalOpen = true;
        }
        if (editid) {
            this.props.getFunction(editid);
        }

        this.state = {
            editorActivated: false,
            functionModalOpen: functionModalOpen,
            archiveModalOpen: false,
            deleteTemplateModalOpen: false,
            removemeTemplateModalOpen: false,
            readOnly: false,
            copyMode: false,
            allPartitionsOpen: false,
            functions: [],
            updateFunctionFormData: false,
            functionType: "all",
            currentMunicipality: this.props.selectedMunicipality.id,
            rowsPerPage: 10,
            functionTemplateId: null,
            functionTemplateMunicipalityId: null
        }

        this.callListFunctions = this.callListFunctions.bind(this);
        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTemplateSubmit = this.handleTemplateSubmit.bind(this);
        this.handleOnlyTemplateSubmit = this.handleOnlyTemplateSubmit.bind(this);
        this.editFunction = this.editFunction.bind(this);
        this.copyFunction = this.copyFunction.bind(this);
        this.openReadOnlyView = this.openReadOnlyView.bind(this);
        this.changePage = this.changePage.bind(this);
        this.archive = this.archive.bind(this);
        this.openArchiveModal = this.openArchiveModal.bind(this);
        this.closeArchiveModal = this.closeArchiveModal.bind(this);
        this.actualDeleteTemplate = this.actualDeleteTemplate.bind(this);
        this.actualRemovemeTemplate = this.actualRemovemeTemplate.bind(this);
        this.closeDeleteTemplateModal = this.closeDeleteTemplateModal.bind(this);
        this.closeRemovemeTemplateModal = this.closeRemovemeTemplateModal.bind(this);
        this.getMunicipalityOptions = this.getMunicipalityOptions.bind(this);
        this.handleMunicipalityChange = this.handleMunicipalityChange.bind(this);
        this.handleFunctionTypeChange = this.handleFunctionTypeChange.bind(this);
        this.handleDateChangeStart = this.handleDateChangeStart.bind(this);
        this.handleDateChangeEnd = this.handleDateChangeEnd.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.createListFunctionFilterObject = this.createListFunctionFilterObject.bind(this);
        this._updateFunctionTemplate = this._updateFunctionTemplate.bind(this);
        this._deleteFunctionTemplate = this._deleteFunctionTemplate.bind(this);
        this._removeMeFromFunctionTemplate = this._removeMeFromFunctionTemplate.bind(this);
        this.updateRowsPerPage = this.updateRowsPerPage.bind(this);
        this.resetDates = this.resetDates.bind(this);
    }

    async componentDidMount() {
        var filters = this.createListFunctionFilterObject();
        filters.municipalityId = this.props.authenticatedUser.mainMunicipalityId;
        filters.functionType = "all";

        this.props.getMunicipality(this.props.authenticatedUser.mainMunicipalityId)
        this.props.listFunctions(filters, (data) => {
            this.setState({functions: data});
            return setFunctions(data);
        });
        
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const lastPart = pathParts[pathParts.length - 1];
        const urlId = parseInt(lastPart, 10);

        const urlParams = new URLSearchParams(window.location.search)
        const urlVar = urlParams.get("link")

        if(urlVar) {
            this.props.getFunction(urlId);
            this.setState({
                ...this.state,
                readOnly: true,
                allPartitionsOpen: true,
                functionModalOpen: true,
                updateFunctionFormData: false
            })
        }
    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.page !== prevProps.page) {
            if (this.props.page === 'yourFunctions') {
                this.props.getMunicipality(this.props.authenticatedUser.mainMunicipalityId)
            }
            this.callListFunctions();
        }
    }

    callListFunctions() {
        var filters = this.createListFunctionFilterObject();
        filters.municipalityId = this.props.selectedMunicipality.id;
        filters.functionType = "all";
        filters.organizerId = this.props.page === 'yourFunctions' ? this.props.authenticatedUser.id : undefined;

        this.props.listFunctions(filters, (data) => {
            this.setState({functions: data});
            return setFunctions(data);
        });
    }

    handleCreateNew(event) {
        event.preventDefault()
        this.props.unsetFunction()
        this.props.unsetLocation()
        this.setState({
            ...this.state,
            readOnly: false,
            copyMode: false,
            allPartitionsOpen: false,
            functionModalOpen: true,
            updateFunctionFormData: true
        })
    }

    archive(event) {
        event.preventDefault()
        this.props.archiveFunction(this.props.selectedFunction.id, () => {
            this.setState({
                ...this.state,
                archiveModalOpen: false,
                functionModalOpen: false,
                editorActivated: false
            });
            this.callListFunctions();
            this.props.listFunctionTemplates(this.props.selectedMunicipality.id);
            return unsetFunction();
        })
    }

    openArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: true})
    }

    closeArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: false})
    }

    closeModal(event, skip="false") {
        event.preventDefault()
        
        const {t} = this.props;
        const translation = t('common.leave_without_saving') + '?';

        if (this.state.readOnly || skip==="skip") {
            this.setState({ ...this.state, functionModalOpen: false });
            window.history.pushState({}, '', '/function');

            const popStateEvent = new PopStateEvent('popstate');
            window.dispatchEvent(popStateEvent);
        } else if (window.confirm(translation)) {
            this.setState({ ...this.state, functionModalOpen: false });
            window.history.pushState({}, '', '/function');

            const popStateEvent = new PopStateEvent('popstate');
            window.dispatchEvent(popStateEvent);
        }
    }

    closeDeleteTemplateModal(event) {
        event.preventDefault()
        this.setState({...this.state, deleteTemplateModalOpen: false})
    }

    closeRemovemeTemplateModal(event) {
        event.preventDefault()
        this.setState({...this.state, removemeTemplateModalOpen: false})
    }

    handleChange(e, changedData) {
        let data = {
            ...this.props.selectedFunction,
            [e.target.name]: e.target.value
        }
        if (changedData) {
            data = {
                ...this.props.selectedFunction,
                ...changedData
            }
        }
        this.props.setFunction(data);
    }

    editFunction(event, data) {
        event.preventDefault()
        this.props.setFunction(data);
        this.setState({
            ...this.state,
            readOnly: false,
            copyMode: false,
            allPartitionsOpen: false,
            functionModalOpen: true,
            updateFunctionFormData: true
        })
    }

    copyFunction(event, data) {
        event.preventDefault()
        console.log(data);

        const copy = {
            eventType: data.eventType,
            locationId: data.locationId,
            locationName: data.locationName,
            locationPostCode: data.locationPostCode,
            locationTypeId: data.locationTypeId,
            organizationId: data.organizationId,
            serviceId: data.serviceId,
            name: data.name,
            municipalityId: data.municipalityId,
            methods: structuredClone(data.methods),
            organizers: structuredClone(data.organizers),
            contentIdentifiers: structuredClone(data.contentIdentifiers),
            startDatetime: data.startDatetime, 
            endDatetime: data.endDatetime, 
            eventHours: data.eventHours
        }

        this.props.setFunction(copy);
        this.setState({
            ...this.state,
            readOnly: false,
            copyMode: true,
            allPartitionsOpen: false,
            functionModalOpen: true,
            updateFunctionFormData: true
        })
    }

    openReadOnlyView(event, data) {
        event.preventDefault();
        this.props.setFunction(data);
        this.setState({
            ...this.state,
            readOnly: true,
            copyMode: false,
            allPartitionsOpen: true,
            functionModalOpen: true,
            updateFunctionFormData: false
        })
    }

    changePage(e, referenceId, limit, direction, isHistoryPage) {

        this.props.paginatedListFunctions(isHistoryPage === true ? this.state.currentMunicipality : null,
            isHistoryPage === true ? this.state.functionType : null,
            isHistoryPage === true ? this.state.startDateEnd : null,
            isHistoryPage === false ? this.props.authenticatedUser.id : null,
            referenceId,
            limit,
            direction,
            (data) => {
                this.setState({functions: data});
                return setFunctions(data);
            });
    }

    _createFunction(data, createTemplate = false, createOnlyTemplate = false) {
        if (!data['municipalityId']) {
            data['municipalityId'] = this.props.selectedMunicipality.id;
        }
        this.props.createFunction(data, createTemplate, data => {
            this.setState({...this.state, functionModalOpen: false,
                editorActivated: false})
            this.callListFunctions();
            this.props.listFunctionTemplates(this.props.selectedMunicipality.id);
            return createOnlyTemplate ? unsetFunction() : setFunction(data);
        }, createOnlyTemplate)
    }

    _saveFunction(id, data) {
        if (!data['municipalityId']) {
            data['municipalityId'] = this.props.selectedMunicipality.id;
        }
        this.props.saveFunction(id, data, data => {
            this.setState({...this.state, functionModalOpen: false,
                editorActivated: false})
            this.callListFunctions();
            this.props.listFunctionTemplates(this.props.selectedMunicipality.id);
            this.props.getReminders();
            return setFunction(data);
        })
    }

    handleOnlyTemplateSubmit(event, props) {
        const functionData = {...props};
        this._createFunction(functionData, true, true);
    }

    handleTemplateSubmit(event, props) {
        const functionData = {...props};
        this._createFunction(functionData, true);
    }

    handleSubmit(event, props) {
        const functionData = {...props};

        const {id} = props;
        if (id) {
            this._saveFunction(id, functionData);
        } else {
            this._createFunction(functionData);
        }
    }

    getModalLabel() {
        const {t} = this.props;
        if (this.state.readOnly) {
            return t('function.read_function');
        }
        if (this.props.selectedFunction.id) {
            return t('function.edit_function');
        }
        return t('function.create_new_function');
    }

    getMunicipalityOptions() {
        const response = [];
        this.props.municipalities.forEach((municipality) => {
            response.push({value: municipality.id, label: municipality.name})
        });
        response.sort((a,b) => a.label.localeCompare(b.label));
        return response;

    }
    getFunctionTypeOptions() {
        const {t} = this.props;
        return [
            {value: "all", label: t('function.all')}, 
            {value: "open", label: t('function.open')}, 
            {value: "group", label: t('function.group')}, 
            {value: "individual", label: t('function.individual')}
        ];
    }


    createListFunctionFilterObject() {
        const tbr = {
            municipalityId: this.state.currentMunicipality,
            functionType: this.state.functionType,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            rows: this.state.rowsPerPage,
            filter: this.state.filter
        }
        return tbr;
    }


// municipalityId, functionType, startDate, endDate, filter, rows, 
    handleMunicipalityChange(e) {
        const val = e.target.value;
        this.props.getMunicipality(val);

        var filters = this.createListFunctionFilterObject();
        filters.municipalityId = val;
        this.props.listFunctions(filters, (data) => {
            this.setState({
                ...this.state,
                currentMunicipality: val,
                functions: data
            });
            return setFunctions(data);
        });
    }

    handleFunctionTypeChange(e) {
        const val = e.target.value;
        var filters = this.createListFunctionFilterObject();
        filters.functionType = val;
        filters.organizerId = this.props.page === 'yourFunctions' ? this.props.authenticatedUser.id : undefined;
        this.props.listFunctions( filters, (data) => {
            this.setState({
                ...this.state,
                functionType: val,
                functions: data
            });
            return setFunctions(data);
        });
    }

    handleDateChangeStart(e) {
        const val = e.target.value;
        var filters = this.createListFunctionFilterObject();
        filters.startDate = val;
        filters.organizerId = this.props.page === 'yourFunctions' ? this.props.authenticatedUser.id : undefined;
        this.props.listFunctions( filters, (data) => {
            this.setState({
                ...this.stage,
                startDate: val,
                functions: data
            });
            return setFunctions(data);
        });
    }

    handleDateChangeEnd(e) {
        const val = e.target.value;
        var filters = this.createListFunctionFilterObject();
        filters.endDate = val;
        filters.organizerId = this.props.page === 'yourFunctions' ? this.props.authenticatedUser.id : undefined;
        this.props.listFunctions( filters, (data) => {
            this.setState({
                ...this.stage,
                endDate: val,
                functions: data
            });
            return setFunctions(data);
        });
    }

    handleFilterChange(e) {
        const val = e.target.value;
        var filters = this.createListFunctionFilterObject();
        filters.filter = val;
        filters.organizerId = this.props.page === 'yourFunctions' ? this.props.authenticatedUser.id : undefined;
        this.props.listFunctions( filters, (data) => {
            this.setState({
                ...this.stage,
                filter: val,
                functions: data
            });
            return setFunctions(data);
        });
    }

    _updateFunctionTemplate(event, props, functionTemplate) {
        if (!functionTemplate) return;
        const functionData = {...props};
        if (!props['municipalityId']) {
            functionData['municipalityId'] = this.props.selectedMunicipality.id;
        }
        this.props.updateFunctionTemplate(functionTemplate.id, functionData, data => {
            this.props.listFunctionTemplates(functionData['municipalityId']);
            return setEmptyAction(data);
        });
    }

    actualDeleteTemplate(event) {
        event.preventDefault();
        this.props.deleteFunctionTemplate(this.state.functionTemplateId, data => {
            this.props.listFunctionTemplates(this.state.functionTemplateMunicipalityId);
            this.setState({
                ...this.stage,
                deleteTemplateModalOpen: false
            });
            return setEmptyAction(data);
        });
    }
    actualRemovemeTemplate(event) {
        event.preventDefault();
        this.props.removemeFunctionTemplate(this.state.functionTemplateId, data => {
            this.props.listFunctionTemplates(this.state.functionTemplateMunicipalityId);
            this.setState({
                ...this.stage,
                removemeTemplateModalOpen: false
            });
            return setEmptyAction(data);
        });
    }

    _deleteFunctionTemplate(event, props, functionTemplate) {
        if (!functionTemplate) return;

        this.setState({
            ...this.state,
            functionTemplateId: functionTemplate.id,
            functionTemplateMunicipalityId: props['municipalityId'] ? props['municipalityId'] : this.props.selectedMunicipality.id,
            deleteTemplateModalOpen: true
        });
    }

    _removeMeFromFunctionTemplate(event, props, functionTemplate) {
        if (!functionTemplate) return;

        this.setState({
            ...this.state,
            functionTemplateId: functionTemplate.id,
            functionTemplateMunicipalityId: props['municipalityId'] ? props['municipalityId'] : this.props.selectedMunicipality.id,
            removemeTemplateModalOpen: true
        });
    }

    updateRowsPerPage(rows) {
        this.setState({
            ...this.state,
            rowsPerPage: rows
        })
    }

    resetDates(e) {
        this.setState({
            ...this.state,
            startDate: null,
            endDate: null
        })
    }


    render() {
        const {t} = this.props;

        let municipalitySelector = (<div className={'hidden'} /> )
        let functionTypeSelector = (<div className={'hidden'} /> )
        let dateSelector = (<div className={'hidden'} /> )
        let filter = (<div className={'hidden'}/> )
        let resetDates = (<div className={'hidden'}/>)
        if(this.props.page ===  'history') {
            municipalitySelector = (<CompDropdown
                label={t('common.municipality')}
                selectedValue={this.props.selectedMunicipality.id}
                rows={this.getMunicipalityOptions()}
                name="municipalityId"
                onChange={this.handleMunicipalityChange}
            />)
        }

            functionTypeSelector = (<CompDropdown
                label={t('function.function_type')}
                selectedValue={this.state.functionType}
                rows={this.getFunctionTypeOptions()}
                name="functionTypeId"
                onChange={this.handleFunctionTypeChange}
            />)
            //dateSelector = (
            //    <CompDatePicker
            //        label={`${t('function.start_date_feedback')}`}
            //        value={formatISODateStr(this.state.startDateEnd)}
            //        name="startDateFeedback"
            //        onChange={this.handleDateChange}
            //    />
            //)
            filter = (<CompSearchInput label={t('function.filter')} onChange={this.handleFilterChange}/>)

            dateSelector = (
                <>
                    <div className={'MuiGrid-item-label label'}>{t('report.start_and_end_date')}</div>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <CompDatePicker
                                value={this.state.startDate}
                                name="startDate"
                                onChange={this.handleDateChangeStart}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CompDatePicker
                                value={this.state.endDate}
                                name="endDate"
                                onChange={this.handleDateChangeEnd}
                            />
                        </Grid>
                    </Grid>
                </>
            )
            resetDates = (<ButtonSecondary onClick={this.resetDates}>{t('function.resetdates')}</ButtonSecondary>)

        
        let functionTemplateButtons = [
            {
                id: "edit",
                type: "main",
                label: t("function.save_function_template_changes"),
                className: "",
                onClick: this._updateFunctionTemplate
            },
            {
                id: "delete",
                type: "attention",
                label: t("function.delete_function_template"),
                className: "",
                onClick: this._deleteFunctionTemplate
            },
            {
                id: "removeme",
                type: "attention",
                label: t("function.remove_me_from_function_template"),
                className: "",
                onClick: this._removeMeFromFunctionTemplate
            }
        ];
        if (this.state.readOnly) {
            functionTemplateButtons = [];
        }

        let actionButtons = [
            {
                type: "secondary",
                label: t('common.close_without_saving'),
                className: "",
                onClick: e => this.closeModal(e, "skip")
            },
            {
                type: "main",
                label: t('function.make_only_template'),
                className: "",
                onClick: this.handleOnlyTemplateSubmit
            },
            {
                type: "main",
                label: t('function.save_and_make_template'),
                className: "",
                onClick: this.handleTemplateSubmit
            },
            {
                type: "main",
                label: t('common.save'),
                className: "",
                onClick: this.handleSubmit
            },
        ];
        if (this.props.selectedFunction && this.props.selectedFunction.id) {
            let canArchive = false;
            if (hasPermission(PERMISSION_ARCHIVE_ANY_FUNCTION, this.props.authenticatedUser)) {
                canArchive = true;
            } else if (hasPermission(PERMISSION_ARCHIVE_OWN_FUNCTION, this.props.authenticatedUser)) {
                if (this.props.selectedFunction.creatorId === this.props.authenticatedUser.id) {
                    canArchive = true;
                }
                else {
                    this.props.selectedFunction.organizers.forEach((organizer) => {
                        if (organizer.id === this.props.authenticatedUser.id) {
                            canArchive = true;
                        }
                    })
                }
            }
            if (canArchive) {
                actionButtons = [
                    {
                        type: "attention",
                        label: t('common.archive'),
                        className: "archive-button",
                        onClick: this.openArchiveModal
                    }];
            } else {
                actionButtons = [];
            }
            actionButtons.push(
                {
                    type: "secondary",
                    label: t('common.close_without_saving'),
                    className: "",
                    onClick: e => this.closeModal(e, "skip")
                }
            );
            actionButtons.push(
                {
                    type: "main",
                    label: t('common.save'),
                    className: "",
                    onClick: this.handleSubmit
                }
            );
        }
        if (this.state.readOnly) {
            actionButtons = [
                {
                    type: "primary",
                    label: t('common.close'),
                    className: "",
                    onClick: this.closeModal
                },
            ];

        }

        let addEditButtons = [];
        if(hasPermissionToHandleMunicipality(this.props.selectedMunicipality.id, this.props.authenticatedUser)) {
            if (hasPermission(PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER, this.props.authenticatedUser) ||
                hasPermission(PERMISSION_CREATE_OWN_FUNCTION, this.props.authenticatedUser) ||
                hasPermission(PERMISSION_CREATE_FUNCTION_TEMPLATE, this.props.authenticatedUser)) {
                addEditButtons.push(
                    <ButtonMain
                        key={'create_new_function'}
                        onClick={this.handleCreateNew}>
                        {t('function.create_new_function')}
                    </ButtonMain>
                );
            }
        }


        let cardActionButtons = [];

        if (hasPermission(PERMISSION_EDIT_ANY_FUNCTIONS, this.props.authenticatedUser) ||
            hasPermission(PERMISSION_EDIT_OWN_FUNCTIONS, this.props.authenticatedUser)) {

            cardActionButtons.push(
                {
                    label: t('common.edit'),
                    icon: process.env.PUBLIC_URL + '/media/images/icons/IconPencil.svg',
                    iconAlt: t('common.edit'),
                    className: "editButton",
                    onClick: this.editFunction
                }
            );
            cardActionButtons.push(
                {
                    label: t('common.copy'),
                    icon: process.env.PUBLIC_URL + '/media/images/icons/IconPencil.svg',
                    iconAlt: t('common.copy'),
                    className: "editButton",
                    onClick: this.copyFunction
                }
            );
        }
        //<Grid item xs={12} sm={3}>
        //    <ButtonMain onClick={this.handleFilterChange}>{t('function.applyFilter')}</ButtonMain>
        //</Grid>
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        {municipalitySelector}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {functionTypeSelector}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {filter}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {dateSelector}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {resetDates}
                    </Grid>
                    <Grid item xs={12}>
                        <CompFunctionCards
                            functions={this.state.functions}
                            rowClick={this.openReadOnlyView}
                            changePage={this.changePage}
                            actions={cardActionButtons}
                            authenticatedUser={this.props.authenticatedUser}
                            page={this.props.page}
                            updateRowsPerPage={this.updateRowsPerPage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    
                    </Grid>
                    <Grid item xs={12} sm={6} className={'align-right'}>
                        <ButtonSpacer>
                            {addEditButtons}
                        </ButtonSpacer>
                    </Grid>
                </Grid>
                    <Modal
                        open={this.state.functionModalOpen && ((this.props.action !== "edit" && (this.props.action !== "add" && !this.props.editid)) || (this.props.selectedFunction.id))}
                        onClose={this.closeModal}
                    >
                        <div className={'modal'}>
                            {((this.props.action !== "edit" && (this.props.action !== "add" && !this.props.editid)) || (this.props.selectedFunction.id)) && 
                            <FunctionForm
                                label={this.getModalLabel()}
                                editorActivated={!this.state.readOnly}
                                copyMode={this.state.copyMode}
                                allPartitionsOpen={this.state.allPartitionsOpen}
                                onChange={this.handleChange}
                                selectedFunction={this.props.selectedFunction}
                                selectedLocation={this.props.selectedLocation}
                                fieldErrors={this.props.functionFieldErrors}
                                actionButtons={actionButtons}
                                functionTemplateButtons={functionTemplateButtons}
                                updateData={this.state.updateFunctionFormData}
                            />
                            }
                        </div>
                    </Modal>
                <Modal
                    open={this.state.archiveModalOpen}
                    onClose={this.closeCreateNewModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalDescription'}>
                            <Trans i18nKey="function.archive_desc"/>
                        </div>
                        <div className={'modalQuestion'}>
                            {t('function.archive_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSecondary
                                key={'cancel'}
                                onClick={this.closeArchiveModal}>
                                {t('common.cancel')}
                            </ButtonSecondary>
                            <ButtonMain
                                key={'archive'}
                                onClick={this.archive}>
                                {t('common.archive')}
                            </ButtonMain>
                        </div>

                    </div>
                </Modal>
                <Modal
                    open={this.state.deleteTemplateModalOpen}
                    onClose={this.closeDeleteTemplateModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalQuestion'}>
                            {t('function.delete_template_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSecondary
                                key={'cancel'}
                                onClick={this.closeDeleteTemplateModal}>
                                {t('common.cancel')}
                            </ButtonSecondary>
                            <ButtonMain
                                key={'deleteTemplate'}
                                onClick={this.actualDeleteTemplate}>
                                {t('function.delete_function_template')}
                            </ButtonMain>
                        </div>

                    </div>
                </Modal>
                <Modal
                    open={this.state.removemeTemplateModalOpen}
                    onClose={this.closeRemovemeTemplateModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalQuestion'}>
                            {t('function.removeme_template_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSecondary
                                key={'cancel'}
                                onClick={this.closeRemovemeTemplateModal}>
                                {t('common.cancel')}
                            </ButtonSecondary>
                            <ButtonMain
                                key={'removemeTemplate'}
                                onClick={this.actualRemovemeTemplate}>
                                {t('function.remove_me_from_function_template')}
                            </ButtonMain>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

const FunctionPageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(FunctionPage));

export {FunctionPageForm};