import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {
    archiveLocation,
    createLocation,
    getLocation,
    listLocations,
    saveLocation,
    setLocation,
    unsetLocation
} from "../location.actions";

import {ButtonMain} from "../../../components/ButtonMain";
import {LocationForm} from "./locationFormComponent";
import {ButtonSecondary} from "../../../components/ButtonSecondary";
import {ListLocations} from "./listLocationsComponent";
import {ListMunicipalities} from "../../municipality/components/listMunicipalitiesComponent";
import { CompPartitionHeader } from '../../../components/PartitionHeader';
import {
    getMunicipality,
    getOrganizationTree,
    listMunicipalities
} from "../../municipality/municipality.actions";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {Trans, withTranslation} from "react-i18next";
import {
    hasPermission,
    PERMISSION_ARCHIVE_LOCATION,
    PERMISSION_ARCHIVE_OWN_ORGANIZATIONS_LOCATION,
    PERMISSION_CREATE_LOCATION_TO_ANY_MUNICIPALITY,
    PERMISSION_CREATE_LOCATION_TO_OWN_MUNICIPALITY,
    PERMISSION_EDIT_LOCATION
} from "../../../permissions";

const mapStateToProps = (state) => {
    return {
        selectedLocation: state.location.selectedLocation,
        selectedMunicipality: state.municipality.selectedMunicipality,
        locationFieldErrors: state.location.locationFieldErrors,
        organizationTree: state.municipality.organizationTree,
        authenticatedUser: state.auth.authenticatedUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listLocations: (municipalityId) => dispatch(listLocations(municipalityId)),
        getLocation: (id) => dispatch(getLocation(id)),
        unsetLocation: () => dispatch(unsetLocation()),
        setLocation: selectedLocation => dispatch(setLocation(selectedLocation)),
        saveLocation: (id, data, onSuccess) => dispatch(saveLocation(id, data, onSuccess)),
        createLocation: (location, onSuccess) => dispatch(createLocation(location, onSuccess)),
        listMunicipalities: () => dispatch(listMunicipalities()),
        getMunicipality: (id) => dispatch(getMunicipality(id)),
        getOrganizationTree: (municipalityId, level) => dispatch(getOrganizationTree(municipalityId, level)),
        archiveLocation: (id, onSuccess) => dispatch(archiveLocation(id, onSuccess)),
    };
}

class LocationPage extends React.Component {
    constructor(props) {
        super(props);
        this.organizationTreeLevel = 10;
        this.props.getOrganizationTree(this.props.selectedMunicipality.id, this.organizationTreeLevel);
        this.props.listMunicipalities();
        this.props.unsetLocation();
        this.state = {
            editorActivated: false,
            createNewLocationModalOpen: false,
            archiveModalOpen: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.closeCreateNewModal = this.closeCreateNewModal.bind(this);
        this.handleActivateEditor = this.handleActivateEditor.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetLocation = this.resetLocation.bind(this);
        this.closeArchiveModal = this.closeArchiveModal.bind(this);
        this.archive = this.archive.bind(this);
        this.openArchiveModal = this.openArchiveModal.bind(this);
        this.getOrganizationTree = this.getOrganizationTree.bind(this);
    }


    componentDidMount() {
        this.props.listLocations(this.props.selectedMunicipality.id)
    }

    handleChange(e) {
        const data = {
            ...this.props.selectedLocation,
            [e.target.name]: e.target.value
        }
        this.props.setLocation(data);
    }

    handleCreateNew(event) {
        event.preventDefault()
        this.props.unsetLocation()
        this.setState({...this.state, createNewLocationModalOpen: true})
    }

    closeCreateNewModal(event) {
        event.preventDefault()
        this.setState({...this.state, createNewLocationModalOpen: false})
    }

    archive(event) {
        event.preventDefault()
        this.props.archiveLocation(this.props.selectedLocation.id, () => {
            this.setState({
                ...this.state,
                archiveModalOpen: false,
                createNewLocationModalOpen: false,
                editorActivated: false
            });
            this.props.listLocations(this.props.selectedMunicipality.id);
            return unsetLocation();
        })
    }

    openArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: true})
    }

    closeArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: false})
    }

    handleActivateEditor() {
        this.setState({...this.state, editorActivated: true})
    }

    handleSubmit(event, props) {
        const data = {...props};
        const {id} = props;
        if (id) {
            this.props.saveLocation(id, data, data => {
                this.props.listLocations(this.props.selectedMunicipality.id);
                this.setState({editorActivated: false})
                return setLocation(data);
            })
        } else {
            data['municipalityId'] = this.props.selectedMunicipality.id;
            this.props.createLocation(data, data => {
                this.setState({...this.state, createNewLocationModalOpen: false,
                    editorActivated: false})
                this.props.listLocations(this.props.selectedMunicipality.id);
                return setLocation(data);
            })
        }
    }

    resetLocation = () => {
        this.selectLocation({id: this.props.selectedLocation.id}, true)
    }

    selectLocation = (row, isAddAction/*, index */) => {
        this.setState({editorActivated: false})
        if (isAddAction) {
            this.props.getLocation(row.id);
        } else {
            this.props.unsetLocation();
        }
    }

    selectMunicipality = (row) => {
        this.setState({editorActivated: false})
        console.log(row);
        this.props.getMunicipality(row.target.value);
        this.props.listLocations(row.target.value);
        this.props.getOrganizationTree(row.target.value, this.organizationTreeLevel);
    }

    getOrganizationTree() {
        if (this.organizationTreeLevel in this.props.organizationTree) {
            return this.props.organizationTree[this.organizationTreeLevel];
        }
        return [];

    }

    render() {
        const {t} = this.props;

        let selectedLocationName = "";
        if (this.props.selectedLocation && this.props.selectedLocation.name) {
            selectedLocationName = this.props.selectedLocation.name;
        }

        let actionButtons = [];
        if (this.state.editorActivated) {
            let canArchive = false;
            if (hasPermission(PERMISSION_ARCHIVE_LOCATION, this.props.authenticatedUser)) {
                canArchive = true;
            } else if (hasPermission(PERMISSION_ARCHIVE_OWN_ORGANIZATIONS_LOCATION, this.props.authenticatedUser)) {
                // fixme main organization missing
                if (this.props.selectedLocation.organizationId === this.props.authenticatedUser.mainOrganizationId) {
                    canArchive = true;
                }
            }
            if (canArchive) {
                actionButtons.push({
                    type: "attention",
                    label: t('common.archive'),
                    className: "archive-button",
                    onClick: this.openArchiveModal
                });
            }

            actionButtons.push({
                type: "secondary",
                label: t('common.cancel_editing'),
                className: "",
                onClick: this.resetLocation
            });
            actionButtons.push({
                type: "main",
                label: t('common.save_changes'),
                className: "",
                onClick: this.handleSubmit
            });
        }

        let addEditButtons = [];

        if (hasPermission(PERMISSION_CREATE_LOCATION_TO_ANY_MUNICIPALITY, this.props.authenticatedUser) ||
            (
                hasPermission(PERMISSION_CREATE_LOCATION_TO_OWN_MUNICIPALITY, this.props.authenticatedUser)
                && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id
            )) {
            addEditButtons.push(
                <ButtonMain disabled={this.state.editorActivated}
                            key={'add_new_location'}
                            onClick={this.handleCreateNew}>
                    {t('location.add_new_location')}
                </ButtonMain>
            );
        }

        if (hasPermission(PERMISSION_CREATE_LOCATION_TO_ANY_MUNICIPALITY, this.props.authenticatedUser) ||
            (
                hasPermission(PERMISSION_EDIT_LOCATION, this.props.authenticatedUser)
                && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id
            )) {
            addEditButtons.push(
                <ButtonMain disabled={this.state.editorActivated}
                            key={'edit_location'}
                            onClick={this.handleActivateEditor}>
                    {t('location.edit_location')}
                </ButtonMain>
            );
        }

        return (
            <div>
                <Grid container direction='row'>
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={t('municipality.municipality_locations')}/>
                        </Grid>
                            <Grid item xs={12}>
                                <ListMunicipalities onSelect={this.selectMunicipality}/>
                            </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid item xs={12}>
                                <ListLocations onSelect={this.selectLocation}/>
                            </Grid>

                            <Grid item xs={12} className={'align-right'}>
                                <ButtonSpacer>
                                    {addEditButtons}
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                        <Grid item xs={12} sm={6}>
                            <LocationForm
                                label={t('location.selected_location_info')}
                                editorActivated={this.state.editorActivated}
                                onChange={this.handleChange}
                                selectedLocation={this.props.selectedLocation}
                                selectedMunicipality={this.props.selectedMunicipality}
                                fieldErrors={this.props.locationFieldErrors}
                                organizationTree={this.getOrganizationTree()}
                                actionButtons={actionButtons}
                            />
                        </Grid>
                    </Grid>
                <Modal
                    open={this.state.createNewLocationModalOpen}
                    onClose={this.closeCreateNewModal}
                >

                    <div className={'modal'}>

                        <LocationForm
                            label={t('location.create_new_location')}
                            editorActivated={this.state.createNewLocationModalOpen}
                            onChange={this.handleChange}
                            selectedLocation={this.props.selectedLocation}
                            selectedMunicipality={this.props.selectedMunicipality}
                            fieldErrors={this.props.locationFieldErrors}
                            organizationTree={this.getOrganizationTree()}
                            actionButtons={[
                                {
                                    type: "secondary",
                                    label: t('common.leave_without_saving'),
                                    className: "",
                                    onClick: this.closeCreateNewModal
                                },
                                {
                                    type: "main",
                                    label: t('location.save_location'),
                                    className: "",
                                    onClick: this.handleSubmit
                                },
                            ]}
                        />

                    </div>
                </Modal>
                <Modal
                    open={this.state.archiveModalOpen}
                    onClose={this.closeArchiveModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalDescription'}>
                            <Trans i18nKey="location.archive_desc" selectedLocationName={selectedLocationName}/>
                        </div>
                        <div className={'modalQuestion'}>
                            {t('location.archive_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSecondary onClick={this.closeArchiveModal}>
                                {t('common.cancel')}
                            </ButtonSecondary>
                            <ButtonMain onClick={this.archive}>
                                {t('common.archive')}
                            </ButtonMain>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

const LocationPageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(LocationPage));

export {LocationPageForm};