import React from "react";

import {Grid} from "@material-ui/core";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {CompTextField} from "../../../components/TextField";
import * as PropTypes from "prop-types";
import {formatDateStr} from "../../../utils";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {useTranslation} from "react-i18next";
import {generateKey, isFunction} from "../../../utils";
import {selectButtonType} from "../../../components/utils";
import {CompSearchList} from "../../../components/SearchList";


const ContentIdentifierFormComponent = (props) => {
    const {
        label,
        onChange,
        editorActivated,
        actionButtons,
        municipalityId = null,
        selectedContentIdentifier = {},
        contentIdentifiers = {},
        fieldErrors = {},
        activeTab = ""
    } = props;
    
    const {t} = useTranslation('translation');

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const handleClick = (event, callback) => {
        event.preventDefault()
        const data = {
            ...selectedContentIdentifier,
            municipalityId: municipalityId
        }
        callback(event, data);
    };

    const selectParent = (row, isAddAction/*, index */) => {
        if (isAddAction) {
            const data = {
                target: {
                    value: row.id,
                    name: "parentId",
                },
            };
            handleChange(data);
        }
    }

    let componentActionButtons = [];
    if (actionButtons) {
        actionButtons.forEach(function (button) {
            const key = generateKey('contIdentEditButton');
            selectButtonType(button, componentActionButtons, key, handleClick);
        });
    }

    return (
        <form noValidate autoComplete="off" className={"contentIdentifier-container"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={label}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <CompTextField label={`${t('content_identifier.identifier_name_fi')}*`}
                                               value={selectedContentIdentifier.titleFi}
                                               disabled={!editorActivated}
                                               name="titleFi"
                                               error={fieldErrors.titleFi}
                                               onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CompTextField label={`${t('content_identifier.identifier_name_sv')}*`}
                                               value={selectedContentIdentifier.titleSv}
                                               disabled={!editorActivated}
                                               name="titleSv"
                                               error={fieldErrors.titleSv}
                                               onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CompTextField label={`${t('content_identifier.identifier_description')} (FI)`}
                                               value={selectedContentIdentifier.descriptionFi}
                                               disabled={!editorActivated}
                                               name="descriptionFi"
                                               error={fieldErrors.descriptionFi}
                                               multiline
                                               rows={6}
                                               onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CompTextField label={`${t('content_identifier.identifier_description')} (SV)`}
                                               value={selectedContentIdentifier.descriptionSv}
                                               disabled={!editorActivated}
                                               name="descriptionSv"
                                               error={fieldErrors.descriptionSv}
                                               multiline
                                               rows={6}
                                               onChange={handleChange}/>
                            </Grid>
                             <Grid item xs={12}>
                                 <CompTextField label={t('content_identifier.timestamp')}
                                                value={formatDateStr(selectedContentIdentifier.createdAt)}
                                                disabled={true}
                                                name="timestamp"
                                                error={fieldErrors.createdAt}/>
                            </Grid>
                             <Grid item xs={12}>
                                 <CompTextField label={t('content_identifier.creator')}
                                                value={selectedContentIdentifier.creator}
                                                disabled={true}
                                                name="creator"
                                                error={fieldErrors.creator}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Grid item xs={12}>
                                {activeTab === 'nationwide' ? (
                                    <div></div>
                                ) : (
                                    <CompSearchList
                                        className={`listComponent`}
                                        rows={contentIdentifiers}
                                        keyColumn={'id'}
                                        valueFunc={(row) => t('report.current_language') === "FINNISH" ? row.titleFi : row.titleSv}
                                        selectedRows={[selectedContentIdentifier.parentId]}
                                        disabled={!editorActivated || municipalityId === null}
                                        error={fieldErrors.parentId}
                                        onSelect={selectParent}>
                                        {t('content_identifier.select_parent')}
                                    </CompSearchList>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container spacing={1}
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={'bottom-button-container'}>
                            <Grid item xs={12} className={'align-right'}>
                                <ButtonSpacer>
                                    {componentActionButtons}
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

ContentIdentifierFormComponent.propTypes = {
    editorActivated: PropTypes.bool,
    onChange: PropTypes.func,
    selectedContentIdentifier: PropTypes.object,
    fieldErrors: PropTypes.object,
    actionButtons: PropTypes.array,
    municipalityId: PropTypes.number
};

export {ContentIdentifierFormComponent};