import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {
    createMunicipality,
    getMunicipality,
    listMunicipalities,
    listMunicipalityUsers,
    saveMunicipality,
    setMunicipalityData,
    unsetMunicipality
} from "../municipality.actions";
import {ListMunicipalities} from "./listMunicipalitiesComponent";
import {MunicipalityFormComponent} from "./municipalityFormComponent";
import {ApiKeyComponent} from "./apiKeyComponent";
import {ButtonMain} from "../../../components/ButtonMain";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {withTranslation} from "react-i18next";
import {MUNICIPALITY_DRAFT, MUNICIPALITY_PUBLISHED} from "../../root/root.utils";
import {hasPermission, PERMISSION_CREATE_MUNICIPALITY, PERMISSION_EDIT_MUNICIPALITY} from "../../../permissions";

const mapStateToProps = (state) => {
    return {
        selectedMunicipality: state.municipality.selectedMunicipality,
        municipalityFieldErrors: state.municipality.municipalityFieldErrors,
        authenticatedUser: state.auth.authenticatedUser,
        provinces: state.root.provinces
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listMunicipalities: () => dispatch(listMunicipalities()),
        getMunicipality: (id) => dispatch(getMunicipality(id)),
        unsetMunicipality: () => dispatch(unsetMunicipality()),
        setMunicipalityData: selectedMunicipality => dispatch(setMunicipalityData(selectedMunicipality)),
        saveMunicipality: (id, data, onSuccess) => dispatch(saveMunicipality(id, data, onSuccess)),
        createMunicipality: (municipality, onSuccess) => dispatch(createMunicipality(municipality, onSuccess)),
    };
}


class MunicipalityPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.listMunicipalities();
        this.state = {
            editorActivated: false,
            createNewMunicipalityModalOpen: false,
            keyModalOpen: false
        }
        this.closeApiKeyModal = this.closeApiKeyModal.bind(this);
        this.openApiKeyModal = this.openApiKeyModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.closeCreateNewModal = this.closeCreateNewModal.bind(this);
        this.handleActivateEditor = this.handleActivateEditor.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetMunicipality = this.resetMunicipality.bind(this);
        this.handleSaveAsDraft = this.handleSaveAsDraft.bind(this);
        this.handleSaveAsPublished = this.handleSaveAsPublished.bind(this);
    }

    handleChange(e) {
        const data = {
            ...this.props.selectedMunicipality,
            [e.target.name]: e.target.value
        }

        this.props.setMunicipalityData(data);
    }

    handleCreateNew(event) {
        event.preventDefault()
        this.props.unsetMunicipality()
        this.setState({...this.state, createNewMunicipalityModalOpen: true})
    }

    closeCreateNewModal(event) {
        event.preventDefault()
        this.props.getMunicipality(this.props.authenticatedUser.mainMunicipalityId);
        this.setState({...this.state, createNewMunicipalityModalOpen: false})
    }

    closeApiKeyModal() {
        this.setState({...this.state, keyModalOpen: false})
    }
    openApiKeyModal() {
        this.setState({...this.state, keyModalOpen: true})
    }

    handleActivateEditor() {
        if (this.props.selectedMunicipality.state === MUNICIPALITY_DRAFT) {
            this.setState({...this.state, createNewMunicipalityModalOpen: true})
        } else {
            this.setState({...this.state, editorActivated: true})
        }
    }

    handleSaveAsDraft(event, props) {
        const data = {...props};
        const {id} = props;
        data.state = MUNICIPALITY_DRAFT;
        this.handleSubmit(data, id);
    }

    handleSaveAsPublished(event, props) {
        const data = {...props};
        const {id} = props;
        data.state = MUNICIPALITY_PUBLISHED;
        this.handleSubmit(data, id);
    }

    handleSubmit(data, id) {
        if (id) {
            this.props.saveMunicipality(id, data, data => {
                this.props.listMunicipalities();
                this.setState({
                    ...this.state, createNewMunicipalityModalOpen: false,
                    editorActivated: false
                })
                return listMunicipalityUsers(id, data);
            })
        } else {
            this.props.createMunicipality(data, data => {
                this.setState({
                    ...this.state, createNewMunicipalityModalOpen: false,
                    editorActivated: false
                })
                this.props.listMunicipalities();
                return listMunicipalityUsers(data.id, data);
            })
        }
    }

    resetMunicipality = () => {
        this.selectMunicipality(
            {target: {value: this.props.selectedMunicipality.id}}
        )
    }

    selectMunicipality = (row ) => {
        this.setState({editorActivated: false})
        this.props.getMunicipality(row.target.value);
    }

    render() {
        const {t} = this.props;

        let actionButtons = [];
        if (this.state.editorActivated) {
            actionButtons.push({
                type: "secondary",
                label: t('common.cancel_editing'),
                className: "",
                onClick: this.resetMunicipality
            });
            actionButtons.push({
                type: "main",
                label: t('common.save_changes'),
                className: "",
                onClick: this.handleSaveAsPublished
            });
        }

        let addEditButtons = [];
        if (hasPermission(PERMISSION_CREATE_MUNICIPALITY, this.props.authenticatedUser)) {
            addEditButtons.push(
                <ButtonMain disabled={this.state.editorActivated}
                            key={'add_new_municipality'}
                            onClick={this.handleCreateNew}>
                    {t("municipality.add_new_municipality")}
                </ButtonMain>
            );
        }
        if (hasPermission(PERMISSION_CREATE_MUNICIPALITY, this.props.authenticatedUser) ||
            (
                hasPermission(PERMISSION_EDIT_MUNICIPALITY, this.props.authenticatedUser)
                && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id
            )) {
            addEditButtons.push(
                <ButtonMain
                    disabled={this.state.editorActivated || Object.keys(this.props.selectedMunicipality).length === 0}
                    key={'edit_municipality'}
                    onClick={this.handleActivateEditor}>
                    {t("municipality.edit_municipality")}
                </ButtonMain>
            );
        }

        return (
            <div>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <ListMunicipalities onSelect={this.selectMunicipality}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={1}
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                            <Grid item xs={12} className={'align-right'}>
                                <ButtonSpacer>
                                    {addEditButtons}
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <MunicipalityFormComponent
                    openApiKeyModal={this.openApiKeyModal}
                    showApiKeyButton={!this.state.editorActivated &&
                        (hasPermission(PERMISSION_CREATE_MUNICIPALITY, this.props.authenticatedUser) || 
                            (hasPermission(PERMISSION_EDIT_MUNICIPALITY, this.props.authenticatedUser)
                            && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id))
                    }
                    label={t("municipality.selected_municipality_info")}
                    editorActivated={this.state.editorActivated}
                    onChange={this.handleChange}
                    selectedMunicipality={this.props.selectedMunicipality}
                    provinces={this.props.provinces}
                    fieldErrors={this.props.municipalityFieldErrors}
                    actionButtons={actionButtons}
                />
                <Modal
                  open={this.state.keyModalOpen}
                  onClose={this.closeApiKeyModal}
                >
                    <div className={'modal'}>
                        <ApiKeyComponent
                            municipalityId={this.props.selectedMunicipality.id}
                            organizationId={null}
                            createApiKey={hasPermission(PERMISSION_CREATE_MUNICIPALITY, this.props.authenticatedUser) ||
                            (hasPermission(PERMISSION_EDIT_MUNICIPALITY, this.props.authenticatedUser)
                            && this.props.authenticatedUser.mainMunicipalityId === this.props.selectedMunicipality.id)}
                            labelText={t("municipality.municipality_key")+" - "+this.props.selectedMunicipality.name}
                            onClose={this.closeApiKeyModal}
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.state.createNewMunicipalityModalOpen}
                    onClose={this.closeCreateNewModal}
                >

                    <div className={'modal'}>

                        <MunicipalityFormComponent
                            label={t("municipality.create_new_municipality")}
                            editorActivated={this.state.createNewMunicipalityModalOpen}
                            onChange={this.handleChange}
                            selectedMunicipality={this.props.selectedMunicipality}
                            provinces={this.props.provinces}
                            fieldErrors={this.props.municipalityFieldErrors}
                            actionButtons={[
                                {
                                    type: "secondary",
                                    label: t("common.leave_without_saving"),
                                    className: "",
                                    onClick: this.closeCreateNewModal
                                },
                                {
                                    type: "main",
                                    label: t("municipality.save_draft"),
                                    className: "",
                                    onClick: this.handleSaveAsDraft
                                },
                                {
                                    type: "main",
                                    label: t("municipality.publish"),
                                    className: "",
                                    onClick: this.handleSaveAsPublished
                                },
                            ]}
                        />

                    </div>
                </Modal>
            </div>
        )
    }
}

const MunicipalityPageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(MunicipalityPage));

export {MunicipalityPageForm};