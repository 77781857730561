import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonSecondary } from "./ButtonSecondary";
import {useTranslation} from "react-i18next";

const InfoDialog = ({ open, handleClose, title, message }) => {
    const {t} = useTranslation('translation');

    return (
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
            <DialogContentText /* className={'label'} */ style={{
                fontFamily: "Open Sans, sans-serif",
                whiteSpace:"pre-wrap",
                fontSize: "14px",
                lineHeight: "22px",
                display: "flex",
                alignItems: "center",
                color: "#0E4264",
                marginBottom: "0"
                }}>
                    {message}
            </DialogContentText>
        </DialogContent>

        <DialogActions>
            <ButtonSecondary onClick={handleClose} color="primary">
            {t('common.cancel')}
            </ButtonSecondary>
        </DialogActions>

        </Dialog>
    );
    };

export default InfoDialog;
