import React, {useState, useEffect} from "react";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { BalloonNotification } from "../../../components/BalloonNotification";
import { CompReportOwnMunicipalityTable } from "./reportOwnMunicipalityTableComponent";
import { DonutChart } from "./DonutChart";
import { BarChart } from "./BarChart";
import { CompDatePicker } from "../../../components/DatePicker";
import {CompTextField} from "../../../components/TextField";
import {CompMultiselectDropdown} from "../../../components/MultiselectDropdown";
import { getOrganizations } from "../../../components/organizationUtils";
import {useTranslation} from "react-i18next";
import { ButtonMain } from "../../../components/ButtonMain";
import {listLocations} from "../../location/location.actions";
import { Margin, usePDF } from "react-to-pdf";
import {
  getOrganizationTree
} from "../../municipality/municipality.actions";
import {
  getOwnMunicipalityReport,
  getOwnMunicipalityReportSuccess,
} from "../report.actions";
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    authenticatedUser: state.auth.authenticatedUser,
    organizationTree: state.municipality.organizationTree,
    locations: state.location.locations
  };
};

const formatLocations = (locationList, locationArray) => {
  var formatted = [];
  for (let i = 0; i < locationArray.length; i++) {
    if (locationArray[i] > -1) {
      formatted.push(""+locationArray[i]);
      formatted.push(locationList.filter(l => l.id === locationArray[i])[0].name);
    }
    else {
      var name = locationList.filter(l => l.id === locationArray[i])[0].name;
      formatted.push(name.slice(0,name.lastIndexOf(" (")));
    }
  }
  return formatted;
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOwnMunicipalityReport: (startDate, endDate, functionName, locationList, locationArray, organizationArray, onSuccess) =>
      dispatch(getOwnMunicipalityReport(startDate, endDate, functionName, formatLocations(locationList, locationArray), organizationArray, onSuccess)),
    getOrganizationTree: (municipalityId, level) => dispatch(getOrganizationTree(municipalityId, level)),
    listLocations: (municipalityId) => dispatch(listLocations(municipalityId, true))
  };
};

const ReportPage = ({
    listLocations,
    getOrganizationTree,
    getOwnMunicipalityReport,
    authenticatedUser,
    organizationTree,
    locations
  }) => {
  const {t} = useTranslation('translation');

  const [startDate, setStartDate] = useState(moment.utc().subtract(14, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"));
  const [functionName, setFunctionName] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [organizationArray, setOrganizationArray] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [data, setData] = useState({});
  const [timer, setTimer] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(t('report.current_language'));
  const [isMobile, setIsMobile] = useState(false)

  if (currentLanguage !== t('report.current_language')) {
    setCurrentLanguage(t('report.current_language'));
  }

  useEffect(() => {
    const deviceCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setIsMobile(deviceCheck)
  }, [])
  
  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: "minun_kuntani.pdf",
    page: { margin: Margin.MEDIUM },
  });


  const handleCaptureAll = () => {
    const screenshots = document.querySelectorAll('.data');
  
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
    const orientation = isMobile ? 'p' : 'l'; 
    const pageSize = isMobile ? 'a4' : [800, 600]; 
  
    const pdf = new jsPDF({
      orientation: orientation,
      unit: 'px',
      format: pageSize
    });
  
    let index = 0;
  
    const addScreenshotToPdf = () => {
      if (index < screenshots.length) {
        const screenshot = screenshots[index];
        html2canvas(screenshot)
          .then((canvas) => {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
  
            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;
  
            const imgData = canvas.toDataURL('image/png');
  
            if (index === 0) {
              pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);
            } else if(index === 1) {
                if(isMobile === true) {
                  pdf.addPage()
                  pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);
                } else {
                  pdf.addImage(imgData, 'PNG', 0, 100, canvasWidth, canvasHeight);
                }
            } else {
              pdf.addPage();
              pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);
            }
  
            index++;
            addScreenshotToPdf();
          });
      } else {
        pdf.save('screenshots.pdf');
      }
    };
    addScreenshotToPdf();
  };

  useEffect(() => {
    getOrganizationTree(authenticatedUser.mainMunicipalityId, 10);
    listLocations(authenticatedUser.mainMunicipalityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    locations.sort((a, b) => a.name.localeCompare(b.name));
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].id < 0) {
        locations[i].name = locations[i].original + " (" + t("report.not_saved") +")";
      }
    }
    setLocationList(locations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations])

  useEffect( () => {
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].id < 0) {
        locations[i].name = locations[i].original + " (" + t("report.not_saved") +")";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage])

  useEffect(() => {
    setOrganizationList(getOrganizations(organizationTree));
  }, [organizationTree])

  useEffect(() => {
    getReportTimer();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, functionName, locationArray, organizationArray])


  const getReportTimer = () => {
    if (timer) {
      clearTimeout(timer)
    }

    const newTimer = setTimeout(() => {
      getReport();
    }, 1000);
    setTimer(newTimer);

    return () => {
      clearTimeout(newTimer);
      setTimer(null);
    };
  }

  const getReport = () => {
    if (startDate !== null && endDate !== null) {
      const locations = [];
      for (var i = 0; i < locationArray.length; i++) {
        locations.push(locationArray[i].id);
      }
      const organizations = [];
      for (i = 0; i < organizationArray.length; i++) {
        organizations.push(organizationArray[i].id);
      }

      getOwnMunicipalityReport(
        startDate,
        endDate,
        functionName,
        locationList,
        locations,
        organizations,
        (data) => {
          setData(data);
          return getOwnMunicipalityReportSuccess(data);
        }
      );
    }
  };


  const getKey = (key, element, defaultValue) => {
    try {
      if (!element[key]) {
        return defaultValue;
      }
      return element[key];
    } catch (err) {
      return defaultValue;
    }
  };

  const getTotal = (key) => getKey(key, data.total, 0);

  const getCollection = (key, translationPath) => {
    const currentData = getKey(key, data.collection, []);

    if (translationPath !== undefined && translationPath !== null) {
      currentData.forEach((item) => {
        const translatedTitle = t(
          `${translationPath}.${item.title}`
        );
        item.title = translatedTitle.substring(
          translatedTitle.lastIndexOf(".") + 1
        );
        item.value = t(`${translationPath}.${item.value}`);
      });
    }
    return currentData;
  };

  const handleLocationChange = (row, isAddAction, index, i) => {
    let value = [...locationArray];
    if (isAddAction) {
      value.push(row);
    } else {
      value.splice(i, 1);
    }
    setLocationArray(value);
  }

  const handleOrganizationChange = (row, isAddAction, index, i) => {
    let value = [...organizationArray];
    if (isAddAction) {
      value.push(row);
    } else {
      value.splice(i, 1);
    }
    setOrganizationArray(value);
  }

  const getWeekSummaries = () => getKey("weekSummaries", data, []);

  const selectedOrganizations = [];
  if (organizationArray) {
      organizationArray.forEach(o => {
        selectedOrganizations.push(o.id);
      });
  }
  const selectedLocations = [];
  if (locationArray) {
      locationArray.forEach(l => {
        selectedLocations.push(l.id);
      });
  }

  return (
    <div className={"reports"}>
      <Grid container ref={targetRef}>
        <Grid item xs={12} md={8}>
          <div className={"MuiGrid-item-label label"}>
            {t("report.start_and_end_date")}
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <CompDatePicker
                value={startDate}
                name="startDate"
                onChange={e => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CompDatePicker
                value={endDate}
                name="endDate"
                onChange={e => setEndDate(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <CompTextField
            label={`${t('function.function_name')}`}
            value={functionName}
            name="functionName"
            onChange={e => setFunctionName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompMultiselectDropdown
            label={`${t('common.locations')}`}
            selectedRows={selectedLocations}
            rows={locationList}
            keyColumn={'id'}
            valueFunc={(row) => row.name}
            name="locations"
            onSelect={handleLocationChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompMultiselectDropdown
            label={`${t('user.organizations')}`}
            selectedRows={selectedOrganizations}
            rows={organizationList}
            keyColumn={'id'}
            valueFunc={(row) => row.name}
            name="organizations"
            onSelect={handleOrganizationChange}
          />
        </Grid>
        <Grid item xs={12} id="pdfDoc1" className="data">
          <div className={"balloon-row"}>
            <BalloonNotification
              title={getTotal("eventCount")}
              content={t("report.functions")}
              severity={"notification"}
            />
            <BalloonNotification
              title={getTotal("sumOfEventHours")}
              content={t("report.event_hours")}
              severity={"notification"}
            />
            <BalloonNotification
              title={getTotal("participantCount")}
              content={t("report.participants")}
              severity={"notification"}
            />
            <BalloonNotification
              title={getTotal("youthOrganizersCount")}
              content={t("report.youth_organizers")}
              severity={"notification"}
            />
            <BalloonNotification
              title={getTotal("sumOfYouthOrganizersSEventHours")}
              content={t("report.youth_organizers_s_event_hours")}
              severity={"notification"}
            />
          </div>
        </Grid>
        <Grid item id="pdfDoc2" className="data">
          <div className={"column-box-container"}>
            <div className={"column-box-title"}>
              {t("report.function_content_identifiers")}
            </div>
            <div className={"column-box-content"}>
              <BarChart
                data={getCollection("sumOfEventContentIdentifiers")}
                valueField="value"
                argumentField="title"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} id="pdfDoc3" className="data">
          <div className={"column-box-container"}>
            <div className={"column-box-title"}>
              {t("report.participants")}
            </div>
            <div className={"column-box-content"}>
              <DonutChart
                data={getKey(
                  "sumOfParticipantGenders",
                  data.collection,
                  []
                )}
                valueField="value"
                argumentField="title"
                t={t}
                translationPath="function"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} id="pdfDoc4" className="data">
          <div className={"column-box-container"}>
            <div className={"column-box-title"}>
              {t("report.participant_genders")}
            </div>
            <div className={"column-box-content"}>
              <DonutChart
                data={getKey(
                  "sumOfParticipantAges",
                  data.collection,
                  []
                )}
                valueField="value"
                argumentField="title"
                t={t}
                translationPath="function"
              />
            </div>
          </div>
        </Grid>
      <Grid item xs={12} id="pdfDoc5" className="data">
        <CompReportOwnMunicipalityTable
          rows={getWeekSummaries()}
          label={t("report.week_report")}
        />
      </Grid>
    </Grid>
    <ButtonMain onClick={() => {
      if(isMobile) {
        console.log(isMobile)
        handleCaptureAll()
      } else {
        console.log(isMobile)
        toPDF()
      }
    }}>{t('report.load_pdf')}</ButtonMain>
    </div>
  );
}

const OwnMunicipalityReportPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("translation")(ReportPage));

export { OwnMunicipalityReportPage };
