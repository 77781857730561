import {FormControl,} from "@material-ui/core";
import React, {useState} from "react";
import Select from 'react-select';
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {isFunction} from "../utils";


const SearchableDropdown = (props) => {
    const {t} = useTranslation('translation');
    let {error} = props
    const {
        rows, label, onChange, labelColumn = 'label',
        valueColumn = 'value', selectedValue = null
    } = props;

    if(error !== undefined && error !== null) {
        error = t("errors."+error);
    } else {
        error=null;
    }

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            setSelected(event);
            onChange({target: event})
        }
    };
    
  const [selected, setSelected] = useState(selectedValue);

    const styles={
        control: (base) => ({ ...base, 'font-size': 14, 'font-family': 'Open Sans,sans-serif;'}),
        menu: (base) => ({ ...base, 'font-size': 14, 'font-family': 'Open Sans,sans-serif;' }),
        menuPortal: (base) => ({ ...base, 'font-size': 14, 'font-family': 'Open Sans,sans-serif;' })
    }

    return (
        <FormControl
            className={`comp-dropdown-container ${props.className ? 'container-' + props.className : ''} ${error ? 'error' : ''}`}>
            <div className={'label'}>{label}</div>
            <div >
                <Select onChange={handleChange} value={selected} options={
                    Array.from(rows.map((row, index) => ({ value: row[valueColumn], label: row[labelColumn], template: row.template})))
                    .sort((a,b) => a.label.localeCompare(b.label))}
                components={{
                    IndicatorSeparator: () => null
                }}
                styles={styles}
                placeholder=""
                inputId="search-template"
                />
            </div>
            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{error}</div>
        </FormControl>
    );
}

SearchableDropdown.propTypes = {
    labelColumn: PropTypes.string,
    valueColumn: PropTypes.string,
    label: PropTypes.string,
    rows: PropTypes.array,
    error: PropTypes.string,
    hasEmptyOption: PropTypes.bool,
    onChange: PropTypes.func,
    selectedValue: PropTypes.string
};

export {SearchableDropdown};