import React, {useState, useEffect} from "react";
import {Grid} from "@material-ui/core";
import {CompTextField} from "../../../components/TextField";
import {
    getKey, createKey, deleteKey
} from "../municipality.actions";
import {ButtonMain} from "../../../components/ButtonMain";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import types from "../municipality.types";
import { ButtonSecondary } from "../../../components/ButtonSecondary";

const ApiKeyWindow = (props) => {
    const {
        getKey,
        municipalityId,
        organizationId,
        createKey,
        deleteKey,
        createApiKey = false,
        labelText,
        onClose,
    } = props;


    const [key, setKey] = useState("");

    useEffect(() => {
        getKey(municipalityId, organizationId, (data) => {
            console.log(data)
            setKey(data.key)
            return {
                type: municipalityId ? types.GET_KEY_MUNICIPALITY : types.GET_KEY_ORGANIZATION,
                payload: data
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const newKey = () => {
        createKey({
            municipalityId: municipalityId,
            organizationId: organizationId
        }, (data) => {
            setKey(data.key)
            return {
                type: types.CREATE_KEY,
                payload: data
            }
        });
    }

    const delKey = () => {
        deleteKey({
            municipalityId: municipalityId,
            organizationId: organizationId
        }, (data) => {
            setKey("")
            return {
                type: types.CREATE_KEY,
                payload: data
            }
        });
    }

    //saveMunicipality: (id, data, onSuccess) => dispatch(saveMunicipality(id, data, onSuccess)),

    const {t} = useTranslation('translation');

    return (
        <>
        <Grid item xs={12}>
            <CompTextField
                label={labelText}
                value={key}
                disabled={true}
                name="name"  
            />
        </Grid>
        <Grid container spacing={0}>
        { createApiKey && 
        <Grid item xs={4}>
            <ButtonMain
                key={'regenerate_api_key'}
                onClick={newKey}>
                {t("municipality.regenerate_api_key")}
            </ButtonMain>
        </Grid> 
        }
        { createApiKey && 
        <Grid item xs={4}>
            <ButtonSecondary
                key={'delete_api_key'}
                onClick={delKey}>
                {t("municipality.delete_api_key")}
            </ButtonSecondary>
        </Grid> 
        }
        <Grid item xs={4}>
            <ButtonMain
                key={'show_api_key'}
                onClick={onClose}>
                {t("common.close")}
            </ButtonMain>
        </Grid> 
        </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        apiKey: state.municipality.apiKey
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getKey: (municipalityId, organizationId, onSuccess) => dispatch(getKey(municipalityId, organizationId, onSuccess)),
        createKey: (data, onSuccess) => dispatch(createKey(data, onSuccess)),
        deleteKey: (data, onSuccess) => dispatch(deleteKey(data, onSuccess))
    };
}

const ApiKeyComponent = connect(mapStateToProps, mapDispatchToProps)(ApiKeyWindow);

export {ApiKeyComponent};