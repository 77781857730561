import React from "react";

import {FormControl, FormLabel, Grid} from "@material-ui/core";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {CompTextField} from "../../../components/TextField";
import {CompRadioGroup} from "../../../components/RadioGroup";
import * as PropTypes from "prop-types";
import {CompTooltipIcon} from "../../../components/TooltipIcon";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {useTranslation} from "react-i18next";
import {generateKey, isFunction} from "../../../utils";
import {MUNICIPALITY_DRAFT} from "../../root/root.utils";
import {selectButtonType} from "../../../components/utils";
import {ButtonMain} from "../../../components/ButtonMain";
import {CompDropdown} from "../../../components/Dropdown";


const MunicipalityFormComponent = (props) => {
    const {
        openApiKeyModal,
        showApiKeyButton = false,
        label,
        onChange,
        editorActivated,
        actionButtons,
        provinces = [],
        selectedMunicipality = {},
        fieldErrors = {}
    } = props;

    const {t} = useTranslation('translation');

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const getProvinceOptions = () => {
        const response = [];
        for (const i in provinces) {
            const province = provinces[i];
            response.push({value: province.id, label: province.name})
        }
        return response;
    }

    const showApiKeyModal = (event) => {
        event.preventDefault()
        openApiKeyModal()
    };

    const handleClick = (event, callback) => {
        event.preventDefault()
        const data = {...selectedMunicipality}
        callback(event, data);
    };

    let componentActionButtons = [];
    if (actionButtons) {
        actionButtons.forEach(function (button) {
            const key = generateKey('muniEditButton');
            selectButtonType(button, componentActionButtons, key, handleClick);
        });
    }

    const organizationOfYouthWorkChoices = [
        {
            label: t('municipality.internally'),
            value: "internally"
        },
        {
            label: t('municipality.split'),
            value: "split"
        },
        {
            label: t('municipality.externally'),
            value: "externally"
        },
    ]

    let title = label;
    if (selectedMunicipality.state === MUNICIPALITY_DRAFT) {
        title = label + " - " + t("municipality.draft");
    }

    return (
        <form noValidate autoComplete="off" className={"municipality-container"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={title}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <CompTextField
                                label={`${t('common.municipality')}*`}
                                value={selectedMunicipality.name}
                                disabled={!editorActivated}
                                name="name"
                                error={fieldErrors.name}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CompDropdown
                                label={`${t('common.province')}*`}
                                selectedValue={selectedMunicipality.provinceId ===undefined || selectedMunicipality.provinceId ===null?'':selectedMunicipality.provinceId}
                                rows={getProvinceOptions()}
                                disabled={!editorActivated}
                                name="provinceId"
                                error={fieldErrors.provinceId}
                                onChange={handleChange}/>
                        </Grid>

                        <Grid item xs={12}>
                            <CompTextField
                                value={selectedMunicipality.population}
                                onChange={handleChange}
                                disabled={!editorActivated}
                                name="population"
                                error={fieldErrors.population}
                                type={'number'}
                                label={`${t('municipality.population')}*`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.no_of_young_people')}*`}
                                value={selectedMunicipality.noOfYoungPeople}
                                disabled={!editorActivated}
                                name="noOfYoungPeople"
                                error={fieldErrors.noOfYoungPeople}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.cgt_euro')}*`}
                                value={selectedMunicipality.cgtEuro}
                                disabled={!editorActivated}
                                name="cgtEuro"
                                error={fieldErrors.cgtEuro}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.grants_received_euro')}*`}
                                value={selectedMunicipality.grantsReceivedEuro}
                                disabled={!editorActivated}
                                name="grantsReceivedEuro"
                                error={fieldErrors.grantsReceivedEuro}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.user_charges_euro')}*`}
                                value={selectedMunicipality.userChargesEuro}
                                disabled={!editorActivated}
                                name="userChargesEuro"
                                error={fieldErrors.userChargesEuro}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.other_funding_euro')}*`}
                                value={selectedMunicipality.otherFundingEuro}
                                disabled={!editorActivated}
                                name="otherFundingEuro"
                                error={fieldErrors.otherFundingEuro}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.youth_work_grants_awarded_euro')}*`}
                                value={selectedMunicipality.youthWorkGrantsAwardedEuro}
                                disabled={!editorActivated}
                                name="youthWorkGrantsAwardedEuro"
                                error={fieldErrors.youthWorkGrantsAwardedEuro}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={t("municipality.municipality_personal_info")}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.lgo_title')}*`}
                                value={selectedMunicipality.lgoTitle}
                                disabled={!editorActivated}
                                name="lgoTitle"
                                error={fieldErrors.lgoTitle}
                                onChange={handleChange}/>
                            <CompTooltipIcon>
                                {t('municipality.tooltip_lgo_title')}
                            </CompTooltipIcon>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.no_managers')}*`}
                                value={selectedMunicipality.noManagers}
                                disabled={!editorActivated}
                                name="noManagers"
                                error={fieldErrors.noManagers}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.no_coordinators')}*`}
                                value={selectedMunicipality.noCoordinators}
                                disabled={!editorActivated}
                                name="noCoordinators"
                                error={fieldErrors.noCoordinators}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.no_youth_workers')}*`}
                                value={selectedMunicipality.noYouthWorkers}
                                disabled={!editorActivated}
                                name="noYouthWorkers"
                                error={fieldErrors.noYouthWorkers}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.fte_of_senior_lgo')}*`}
                                value={selectedMunicipality.fteOfSeniorLgo}
                                disabled={!editorActivated}
                                name="fteOfSeniorLgo"
                                error={fieldErrors.fteOfSeniorLgo}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.fte_of_managers')}*`}
                                value={selectedMunicipality.fteOfManagers}
                                disabled={!editorActivated}
                                name="fteOfManagers"
                                error={fieldErrors.fteOfManagers}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.fte_of_coordinators')}*`}
                                value={selectedMunicipality.fteOfCoordinators}
                                disabled={!editorActivated}
                                name="fteOfCoordinators"
                                error={fieldErrors.fteOfCoordinators}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.fte_of_youth_workers')}*`}
                                value={selectedMunicipality.fteOfYouthWorkers}
                                disabled={!editorActivated}
                                name="fteOfYouthWorkers"
                                error={fieldErrors.fteOfYouthWorkers}
                                type={'number'}
                                onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={t('municipality.youth_work_management_of_municipality')}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    {t('municipality.organization_of_youth_work')}*
                                </FormLabel>
                                <CompRadioGroup
                                    name="organizationOfYouthWork"
                                    selected={selectedMunicipality.organizationOfYouthWork}
                                    disabled={!editorActivated}
                                    error={fieldErrors.organizationOfYouthWork}
                                    rows={organizationOfYouthWorkChoices}
                                    onChange={handleChange}/>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('municipality.governance_of_municipality')}*`}
                                value={selectedMunicipality.governanceOfMunicipality}
                                disabled={!editorActivated}
                                name="governanceOfMunicipality"
                                error={fieldErrors.governanceOfMunicipality}
                                onChange={handleChange}/>
                        </Grid>                        
                        { showApiKeyButton && true &&
                        <Grid item xs={12}>
                            <ButtonMain
                                key={'show_api_key'}
                                onClick={showApiKeyModal}>
                                {t("municipality.show_api_key")}
                            </ButtonMain>
                        </Grid> 
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container spacing={1}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={'bottom-button-container'}>
                        <Grid item xs={12} className={'align-right'}>
                            <ButtonSpacer>
                                {componentActionButtons}
                            </ButtonSpacer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

MunicipalityFormComponent.propTypes = {
    editorActivated: PropTypes.bool,
    onChange: PropTypes.func,
    selectedMunicipality: PropTypes.object,
    fieldErrors: PropTypes.object,
    actionButtons: PropTypes.array,
    provinces: PropTypes.array
};

export {MunicipalityFormComponent};