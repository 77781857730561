import React, { useState, useEffect } from "react";

import {Grid} from "@material-ui/core";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {CompTextField} from "../../../components/TextField";
import * as PropTypes from "prop-types";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {connect} from "react-redux";
import {setUser} from "../user.actions";
import {generateKey, isFunction} from "../../../utils";
import {useTranslation} from "react-i18next";
import {selectButtonType} from "../../../components/utils";
import { CompCheckbox } from "../../../components/Checkbox";
import {CompDropdown} from "../../../components/Dropdown";
import {CompListTree} from "../../../components/ListTree";
import {getOrganizationTree} from "../../municipality/municipality.actions";
import {
    hasPermission,
    hasPermissionToHandleRole,
    PERMISSION_INVITE_NEW_ADMIN,
} from "../../../permissions";

const UserFormComponent = ({
                               authenticatedUser,
                               municipalities,
                               organizationTree,
                               getOrganizationTree,
                               userRoles,
                               props
                           }) => {
    const {
        label,
        onChange,
        editorActivated,
        actionButtons,
        selectedUser = {},
        fieldErrors = {},
    } = props;

    const {t} = useTranslation('translation');

    const checkUserRoles = [
        'ROLE_ADMIN_SYSTEM',
        'ROLE_ADMIN_MUNICIPALITY',
        'ROLE_LEADER_MUNICIPALITY'
    ]

    const handleCheckboxChange = (event, value, row) => {
        if (!row.key) {
            console.error("undefined", row);
            return;
        }

        selectedUser[row.key] = event.target.checked;
    
        const data = {
            target: {
                value: selectedUser[row.key],
                name: row.key,
            },
        };
    
        handleChange(data);
    };

    const rows = [
        { 
            key: 'responsiblePerson',
            value: selectedUser.responsiblePerson,
            label: t('user.responsiblePerson'),
            disabled: !editorActivated || !checkUserRoles.includes(selectedUser.role),
            infoText: t('user.responsibleInfotext')
        },
        { 
            key:'contactPerson',
            value: selectedUser.contactPerson,
            label: t('user.contactPerson'),
            disabled: !editorActivated || !checkUserRoles.includes(selectedUser.role),
            infoText: t('user.contactInfotext')
        },
        { 
            key: 'purchaserPerson',
            value: selectedUser.purchaserPerson,
            label: t('user.purchaserPerson'),
            infoText: t('user.purchaserInfotext'),
            disabled: !editorActivated
        },
      ];

    if (municipalities) {
        municipalities.sort((a,b) => a.name.localeCompare(b.name));
    }

    const selectOrganization = (row, isAddAction, index, i) => {
        let newSelectedOrganizations = [];
        if (selectedUser.organizations !== undefined && selectedUser.organizations != null) {
            newSelectedOrganizations = [...selectedUser.organizations];
        }

        if (isAddAction) { /* add */
            newSelectedOrganizations.push(row)
        } else { /* remove */
            newSelectedOrganizations.splice(i, 1)
        }

        const data = {
            target: {
                value: newSelectedOrganizations,
                name: 'organizations',
            },
        };
        handleChange(data);
    }

    const handleMunicipalityChange = (event) => {
        getOrganizationTree(event.target.value);
        handleChange(event);
    };

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const handleRoleChange = (event) => {

        console.log(event.target.value);
        if (!checkUserRoles.includes(event.target.value)) {
            console.log("here");
            selectedUser.responsiblePerson =  false;
            selectedUser.contactPerson = false;
        }


        handleChange(event);
    };


    const handleClick = (event, callback) => {
        event.preventDefault()
        const data = {...selectedUser}
        callback(event, data);
    };

    const getOrganizationIds = (organizations) => {
        if (organizations === undefined || organizations == null) {
            return [];
        }
        return organizations.map(function (organization) {
            return organization.id;
        });
    }


    let componentActionButtons = [];
    if (actionButtons) {
        actionButtons.forEach(function (button) {
            const key = generateKey('userEditButton');
            selectButtonType(button, componentActionButtons, key, handleClick);
        });
    }

    const roles = [];
    userRoles.forEach((role) => {
        if(hasPermissionToHandleRole(role, authenticatedUser)) {
            roles.push({
                id: role,
                value: t("common." + role)
            })
        }

    })

    let municipalityChangeActive = false;
    if (editorActivated && hasPermission(PERMISSION_INVITE_NEW_ADMIN, authenticatedUser)) {
        municipalityChangeActive = true;
    }

    if(!selectedUser.mainMunicipalityId) {
        selectedUser.mainMunicipalityId = authenticatedUser.mainMunicipalityId;
    }

    return (
        <form noValidate autoComplete="off" className={"user-container"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={label}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CompTextField
                        label={`${t("user.first_name")}*`}
                        value={selectedUser.firstName}
                        onChange={handleChange}
                        disabled={!editorActivated}
                        error={fieldErrors.firstName}
                        name="firstName"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CompTextField
                        label={`${t("user.last_name")}*`}
                        value={selectedUser.lastName}
                        onChange={handleChange}
                        disabled={!editorActivated}
                        error={fieldErrors.lastName}
                        name="lastName"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CompTextField
                        label={t("user.title")}
                        value={selectedUser.title}
                        onChange={handleChange}
                        disabled={!editorActivated}
                        error={fieldErrors.title}
                        name="title"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CompDropdown
                        label={t("user.user_role")}
                        valueColumn={"id"}
                        labelColumn={"value"}
                        rows={roles}
                        error={fieldErrors.userRole}
                        hasEmptyOption={false}
                        onChange={handleRoleChange}
                        userInfo={true}
                        disabled={!editorActivated}
                        name="role"
                        selectedValue={selectedUser.role}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CompDropdown
                        label={`${t("common.municipality")}*`}
                        valueColumn={"id"}
                        labelColumn={"name"}
                        rows={municipalities}
                        error={fieldErrors.mainMunicipalityId}
                        hasEmptyOption={false}
                        onChange={handleMunicipalityChange}
                        disabled={!municipalityChangeActive}
                        name="mainMunicipalityId"
                        selectedValue={selectedUser.mainMunicipalityId?selectedUser.mainMunicipalityId: null}

                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
                        <Grid item xs={12} sm={6}>
                            <CompCheckbox
                                rows={rows}
                                error={fieldErrors.roles}
                                selected={selectedUser}
                                name="roleCheckbox"
                                className="roles"
                                onChange={handleCheckboxChange}
                                booleanmode={true}
                                infoMode={true}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <CompTextField
                                value={selectedUser.email}
                                onChange={handleChange}
                                disabled={!editorActivated}
                                name="email"
                                error={fieldErrors.email}
                                type={'email'}
                                label={`${t("user.email")}*`}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <CompListTree
                            onSelect={selectOrganization}
                            rows={organizationTree[3]}
                            selectedRows={getOrganizationIds(selectedUser.organizations)}
                            disabled={!editorActivated}
                            error={fieldErrors.organizations}
                            name="organizations"
                            wholeTreeOpen={true}
                            keyColumn={'id'}
                            valueColumn={'name'}
                        >
                            {t('user.organizations')}
                        </CompListTree>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} className={'align-right'}>
                    <ButtonSpacer>
                        {componentActionButtons}
                    </ButtonSpacer>
                </Grid>
            </Grid>
        </form>
    )
}

UserFormComponent.propTypes = {
    label: PropTypes.string,
    editorActivated: PropTypes.bool,
    onChange: PropTypes.func,
    selectedUser: PropTypes.object,
    fieldErrors: PropTypes.object,
    actionButtons: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        authenticatedUser: state.auth.authenticatedUser,
        municipalities: state.municipality.municipalities,
        organizationTree: state.municipality.organizationTree,
        userRoles: state.root.userRoles,
        props: ownProps
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setUser: (data) => dispatch(setUser(data)),
        getOrganizationTree: (municipalityId, level) => dispatch(getOrganizationTree(municipalityId, level)),
    };
}

const UserForm = connect(mapStateToProps, mapDispatchToProps)(UserFormComponent);

export {UserForm};