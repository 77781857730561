import types from "./municipality.types";
import {API_END, API_START} from "../api/types";
import {types as auth_types} from "../auth/auth.types";

export const initialState = {
    municipalities: [],
    organizations: [],
    isLoadingMunicipalitiesListData: false,
    isLoadingOrganizationsListData: false,
    selectedMunicipality: {},
    selectedOrganization: {},
    municipalityFieldErrors: {},
    organizationFieldErrors: {},
    organizationTree: {},
    selectedMunicipalityUsers: [],
    selectedTemplateHandlers: [],
    apiKey: ""
}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case auth_types.AUTH_LOGIN:
            return initialState;
        case auth_types.AUTH_LOGOUT:
            return initialState;
        case types.SET_MUNICIPALITIES_LIST:
            return {
                ...state,
                municipalities: action.payload
            }
        case types.SET_MUNICIPALITY:
            return {
                ...state,
                municipalityFieldErrors: {},
                selectedMunicipality: action.payload.municipalityData,
                selectedOrganization: {},
                selectedMunicipalityUsers:action.payload.municipalityUserData,
                selectedTemplateHandlers:action.payload.municipalityUserData
                
            }
        case types.SET_MUNICIPALITY_SILENT:
            return {
                ...state,
                selectedMunicipalityUsers:action.payload.municipalityUserData,
                selectedTemplateHandlers:action.payload.municipalityUserData
            }
        case types.SET_MUNICIPALITY_DATA:
            return {
                ...state,
                selectedMunicipality: action.payload
            }
        case types.UNSET_MUNICIPALITY:
            return {
                ...state,
                municipalityFieldErrors: {},
                selectedMunicipality: {},
                selectedOrganization: {},
                selectedMunicipalityUsers: [],
                selectedTemplateHandlers: []
            }
        case types.SET_ORGANIZATIONS_LIST:
            return {
                ...state,
                organizations: action.payload
            }
        case types.SET_ORGANIZATION:
            return {
                ...state,
                organizationFieldErrors: {},
                selectedOrganization: action.payload
            }
        case types.UNSET_ORGANIZATION:
            return {
                ...state,
                organizationFieldErrors: {},
                selectedOrganization: {}
            }
        case types.SET_ORGANIZATION_TREE:
            return {
                ...state,
                organizationTree: {
                    ...state.organizationTree,
                    [action.payload.level]: action.payload.data
                }
            }
        case API_START:
            if (action.payload === types.FETCH_MUNICIPALITIES_LIST) {
                return {
                    ...state,
                    isLoadingMunicipalitiesListData: true
                };
            }
            if (action.payload === types.FETCH_ORGANIZATIONS_LIST) {
                return {
                    ...state,
                    isLoadingOrganizationsListData: true
                };
            }
            return state
        case API_END:
            if (action.payload === types.FETCH_MUNICIPALITIES_LIST) {
                return {
                    ...state,
                    isLoadingMunicipalitiesListData: false
                };
            }
            if (action.payload === types.FETCH_ORGANIZATIONS_LIST) {
                return {
                    ...state,
                    isLoadingOrganizationsListData: false
                };
            }
            return state
        case types.MUNICIPALITY_API_ERROR:
        case types.ORGANIZATION_API_ERROR:
            if (action.payload == null) {
                return state;
            }
            if(typeof action.payload === "string") {
                console.log("API error: " + action.payload)
                return state
            } else if('status' in action.payload && action.payload.status === 405) {
                console.log("API error: " + action.payload.error)
                return state
            } else if('data' in action.payload) {
                console.log("API error: " + action.payload.message)
                if(action.type === types.MUNICIPALITY_API_ERROR) {
                    return {
                        ...state,
                        municipalityFieldErrors: action.payload.data
                    }
                } else {
                    return {
                        ...state,
                        organizationFieldErrors: action.payload.data
                    }
                }
            } else {
                console.log("API error: " + action.payload)
                return state
            }
        case types.GET_KEY_MUNICIPALITY:
        case types.GET_KEY_ORGANIZATION:
        case types.CREATE_KEY:
            return {
                ...state,
                apiKey: action.payload
            }
        default:
            // Do not use the default case
            // A dispatch action will pass through each of the reducers associated with this one.
            // An action at the other end of your application could affect this state
            // without being expressed in the code. This should be avoided.
            return state
    }
}
